import { useState } from 'react'
import Button from '../components/common/TheButton'
import CodeList from '../components/common/TheCodeList'
import ScrollTop from '../components/common/TheScrollTop'
import { Tab, Tabs } from '../components/common/TheTab'

export default function Approval() {
  const [progress, setProgress] = useState()

  const list = [
    {
      code: '단순요청',
      code_number: 'SR05000003590',
      state: '승인',
      content: '담당자를 변경해주세요.',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '변경요청',
      code_number: 'SR05000003590',
      state: '반려',
      content: '시스템 설치에 관한 문의드립니다..',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '문제/개선',
      code_number: 'SR05000003590',
      state: '승인',
      content: '문제 해결',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '장애요청',
      code_number: 'SR05000003590',
      state: '승인',
      content: '문제 해결',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '릴리스',
      code_number: 'SR05000003590',
      state: '승인',
      content: '릴리즈 요청사항입니다.',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
  ]

  return (
    <main className='content pt-60'>
      <div className='pt-10'>
        <Tabs activeValue={2}>
          <Tab
            label='작성한 문서'
            disabled
          />
          <Tab label='결재할 문서 (99+)' />
          <Tab label='결재한 문서' />
        </Tabs>
      </div>
      <div className='pb-50 pl-16 pr-16'>
        <CodeList
          list={list}
          progress={progress}
        />

        {list.length === 0 ? null : (
          <Button
            type='shuffle'
            variant='secondary'
            overrideStyle={{
              width: '100%',
              height: '46px',
              marginTop: '14px',
              marginLeft: 0,
              fontSize: '15px',
              color: '#333333',
              border: '1px solid #cccccc',
            }}>
            더보기
            <span className='arrow__icon expand' />
          </Button>
        )}
      </div>
      <ScrollTop />
    </main>
  )
}
