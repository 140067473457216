import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../components/common/TheButton'
import Alert from '../components/common/TheAlert'

export default function Login() {
  const navigate = useNavigate()

  const [isShowAlert, setIsShowAlert] = useState(false)

  const onChangeAlertState = useCallback(value => {
    setIsShowAlert(value)
  }, [])

  return (
    <main className='content login'>
      <div className='loginBox lock'>
        <i className='logo'>USTRA ITSM</i>
        <h1 className='page__title'>계정 잠김</h1>

        <div className='mt-20'>
          계정이 잠겼습니다. 잠금을 해제하려면 아래 이메일로 패스워드 다시 받기
          버튼을 클릭하여 주세요.
        </div>

        <Button onClick={() => onChangeAlertState(true)}>
          이메일로 패스워드 재발급
        </Button>

        <Link to='/ui-page/login'>
          <div className='login__link'>로그인 페이지로 가기</div>
        </Link>
      </div>
      <footer className='footer'>
        <p className='footer__copyright'>
          COPYRIGHT © 2023 GS ITM. ALL RIGHTS RESERVED.
        </p>
      </footer>

      <Alert
        isShow={isShowAlert}
        type='confirm'
        content={`안내메일이 발송되었습니다. 로그인 페이지로 이동합니다.`}
        hideClose
        onConfirm={() => navigate('/ui-page/login')}
      />
    </main>
  )
}
