import { createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'

const reqTypeAdapter = createEntityAdapter()
const mylistProgressAdapter = createEntityAdapter()

const initialReqType = reqTypeAdapter.getInitialState()
const initialMylistProgress = mylistProgressAdapter.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getReqType: builder.query({
      query: body => ({
        url: '/api/setcmmcode',
        method: 'POST',
        body,
      }),
      transformResponse: response => {
        const [{ cmmCodList }] = response
        let results = cmmCodList.map((result, index) => {
          return {
            id: index + 1,
            ...result,
          }
        })
        results = [{ id: 0, cmmCodCl: '', cmmCodNm: '전체' }, ...results]
        return reqTypeAdapter.setAll(initialReqType, results)
      },
      providesTags: ['Mylist'],
    }),
    getMylistProgress: builder.query({
      query: params => ({
        url: '/approval/mylist/mylistProg',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return reqTypeAdapter.setAll(initialMylistProgress, results)
      },
      providesTags: ['MylistProgress'],
    }),
  }),
})

export const {
  useGetMylistProgressQuery,
  useLazyGetMylistProgressQuery,
  useGetReqTypeQuery,
} = extendedApiSlice
