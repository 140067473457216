import { useCallback, useLayoutEffect, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Outlet, useLocation } from 'react-router-dom'
import '../assets/scss/index.scss'

export default function View() {
  const navigation = [
    {
      id: 0,
      name: '결재함 관리',
      icon: 'InboxIcon',
      current: false,
      childNavigation: [{ id: 0, name: '결재 문서함', to: 'approval' }],
    },
    {
      id: 1,
      name: '서비스 확인',
      icon: 'UsersIcon',
      current: false,
      childNavigation: [
        { id: 1, name: '요청 현황 조회', to: 'search-request' },
        { id: 2, name: '만족도 조사', to: 'satisfaction' },
      ],
    },
    {
      id: 2,
      name: '서비스 처리',
      icon: 'XMarkIcon',
      current: false,
      childNavigation: [{ id: 3, name: '마이리스트', to: 'my-list' }],
    },
    {
      id: 3,
      name: '현황 통계',
      icon: 'ChartBarIcon',
      current: false,
      childNavigation: [{ id: 5, name: '요청 현황(종합)', to: 'stats-total' }],
    },
  ]

  const currentLocation = useLocation()
  const pathnames = currentLocation.pathname.split('/')[2]
  const [menuOpen, setMenuOpen] = useState(false)
  const [currentMenu, setCurrentMenu] = useState('')

  const handleMenuOpen = useCallback(
    open => {
      setMenuOpen(open)
    },
    [menuOpen]
  )

  useLayoutEffect(() => {
    navigation.map(item => {
      const menu = item.childNavigation
      menu.map(subMenu => {
        if (pathnames === 'main') {
          setCurrentMenu('')
        } else if (pathnames === subMenu.to) {
          setCurrentMenu(subMenu.name)
        }
      })
    })
  }, [pathnames, currentMenu, navigation])

  return (
    <div
      className={
        !currentLocation.pathname.includes('login') ? 'wrapper main' : 'wrapper'
      }>
      <Header
        menuOpen={menuOpen}
        handleMenuOpen={handleMenuOpen}
        currentMenu={currentMenu}
      />
      <Sidebar
        menuOpen={menuOpen}
        handleMenuOpen={handleMenuOpen}
        navigation={navigation}
      />
      <div className='container'>
        <Outlet context={{ currentMenu }} />
      </div>
    </div>
  )
}
