import SelectBox from '@toast-ui/select-box'
import '@toast-ui/select-box/dist/toastui-select-box.min.css'
import { useEffect, useRef, useState } from 'react'

// 현재는 Single Select만 가능
const Select = ({
  options,
  label,
  placeholder = '',
  disabled,
  readonly,
  hover,
  onChange,
  className = '',
  width,
}) => {
  const selectClass = [
    (disabled && 'is-disabled') || '',
    (readonly && 'is-readonly') || '',
    (hover && 'is-hover') || '',
  ]

  const [value, setValue] = useState('')
  const ref = useRef(null)

  useEffect(() => {
    const selectBox = new SelectBox(ref.current, {
      placeholder: placeholder,
      data: options,
      showIcon: false,
      disabled: !!disabled || !!readonly ? true : false,
    })

    selectBox.select(value)

    selectBox.on('change', e => {
      setValue(e.curr.value)
      onChange(e)
    })

    return () => selectBox.destroy()
  }, [placeholder, options, disabled, readonly, value, onChange])

  return (
    <>
      {!!label && <span className='text-primary fw-600'> {label} </span>}
      <div
        ref={ref}
        className={`formSelect mt-6 mb-20 ${className} ${selectClass?.join(
          ' '
        )}`}
        style={{ width: width ? width : '' }}></div>
    </>
  )
}

export default Select
