// 요청 처리현황용 결재순서 API
const OrderApproval = ({ orderData }) => {
  function getStepTextValue(stepText) {
    if (stepText === '승인') {
      return 'approval'
    } else if (stepText === '진행중') {
      return 'proceed'
    } else if (stepText === '반려') {
      return 'reject'
    } else if (stepText === '합의') {
      return 'agree'
    } else if (stepText === '대기') {
      return 'waiting'
    } else if (stepText === '기각') {
      return 'dismiss'
    } else {
      return 'display-none'
    }
  }

  function getApprovalValue(srOrd, apprTy) {
    if (srOrd === 0) {
      if (apprTy === 'A') {
        return '작성자'
      }
    } else {
      if (apprTy === 'A') {
        return '결재'
      } else if (apprTy === 'C') {
        return '합의'
      } else {
        return '확인'
      }
    }
  }

  return (
    <div className='approval__order'>
      <h3>결재순서</h3>
      <ol className='approval__orderList'>
        {orderData.map((data, index) => (
          <li key={index}>
            <div>
              <span className={`step ${getStepTextValue(data.stepText)}`}>
                {data.stepText}
              </span>
              <span className='status'>
                {getApprovalValue(data.status[0], data.status[1])}
              </span>
              <span className='name'>
                {data.name} {data.titleNm}
              </span>
              <span className='date'>{data.date}</span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default OrderApproval
