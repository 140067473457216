import { useLoaderData, useOutletContext } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import {
  useGetApprLinesQuery,
  useGetRequestInfoQuery,
} from './approvalDetailSlice'

import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Alert from '../../components/common/TheAlert'
import Button from '../../components/common/TheButton'
import Input from '../../components/common/TheInput'
import Loader from '../../components/common/TheLoader'
import OrderApproval from '../../components/common/TheOrderApproval'
import RequestContent from '../../components/common/TheRequestContent'
import { selectDomain } from '../auth/domainSlice'
import {
  useApprAgreeMutation,
  useApprReturnMutation,
  useWithDrawMutation,
} from './approvalSlice'

export default function ApprovalDetail() {
  const { user } = useAuth()
  const paramData = useLoaderData()
  const [currentMenu, setCurrentMenu] = useOutletContext()
  const srId = paramData.srId
  const createEmpId = paramData.createEmpId
  const userId = user.id

  // 회사 경로 import
  const domainsPath = useSelector(selectDomain)

  // 도메인 경로 찾기
  let domainAd = null
  if (domainsPath && domainsPath.entities) {
    const entities = domainsPath.entities

    // entities 객체를 순회하며 coId를 체크하여 user.domain과 일치하는 객체의 DomainAd 값을 찾음
    for (const key in entities) {
      if (entities[key].coId === user.domain) {
        domainAd = entities[key].domainAd
        break
      }
    }
  }

  const {
    data: apprLines = [],
    isLoading: isApprLinesLoading,
    isFetching: isApprLinesFetching,
    isSuccess: isApprLinesSuccess,
    isError: isApprLinesError,
    error: apprLinesError,
  } = useGetApprLinesQuery({
    srId,
    createCoId: user.domain,
    createEmpId,
  })

  const {
    data: requestInfo = [],
    isLoading: isRequestInfoLoading,
    isFetching: isRequestInfoFetching,
    isSuccess: isRequestInfoSuccess,
    isError: isRequestInfoError,
    error: requestInfoError,
  } = useGetRequestInfoQuery({
    srId,
    createEmpId,
    createCoId: user.domain,
  })

  let inProgressPersonInfo
  Object.values(apprLines).map(person =>
    person.map(person => {
      if (person && person.admitStatusNm === '진행중') {
        inProgressPersonInfo = person
      }
    })
  )

  let firstApprover
  let apprLinesArr = Object.values(apprLines)
  if (apprLinesArr[0]?.length) {
    firstApprover = apprLinesArr[0][1]
  }

  let waitingPersonInfo
  let waitingPerson = Object.values(apprLines).map(person =>
    person.filter(
      person =>
        person.admitStatusNm === '승인' ||
        person.admitStatusNm === '보류' ||
        person.admitStatusNm === '대기'
    )
  )
  if (waitingPerson[0]?.length) {
    waitingPersonInfo = waitingPerson[0][0]
  }

  const requestType = requestInfo?.srinfo?.reqClNm
  const contents = requestInfo.srinfo
  const type = requestInfo?.srinfo?.reqClNm
  const filelist = requestInfo?.filelist
  let information = ''

  let requestInfos = requestInfo.srinfo
  let newItem = ''

  if (!!requestInfo.srinfo) {
    newItem = {
      ...requestInfos,
      title: requestInfos.srTitlNm,
      content: requestInfos.srCn,
      srCnTmlSe: requestInfos.srCnTmlSe,
      requestService: requestInfos.servPathNm,
      requestDate: requestInfos.reqDt,
      receptionist: requestInfos.qusrNm,
      completeHopeDate: `${requestInfos.chgCmplHopeDd} ${requestInfos.chgCmplHopeHh}:${requestInfos.chgCmplHopeMm}`,
      addContent: requestInfos.secuCn,
      code: requestInfos.reqTyNm,
      code_number: `${requestInfos.qusrCompId}-${requestInfos.qusrId}-${srId}`,
      domainAd: domainAd,
    }
  }

  let apprLineList = apprLines.apprLines
  if (!Array.isArray(apprLineList)) {
    apprLineList = []
  }
  let copyApprLineList = [...apprLineList]
  let newOrderData = copyApprLineList.map(item => {
    return {
      ...item,
      stepText: item.admitStatusNm,
      status: item.apprTy,
      name: item.userNm,
      date: item.apprDt,
    }
  })

  const [isDetailLoading, setIsDetailLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      // Loader 제거
      setIsDetailLoading(false)
    }, 3000)
  }, [])

  if (isApprLinesSuccess || isRequestInfoSuccess) {
    information = (
      <>
        <RequestContent
          item={newItem}
          filelist={filelist}
        />
      </>
    )
  }

  if (isApprLinesLoading || isRequestInfoLoading) {
    information = <Loader className='loading' />
  }

  //회수
  let withdrawValue = {}
  const [resultWithDraw, { isLoading: withDrawIsLoading }] =
    useWithDrawMutation()
  if (waitingPersonInfo && requestInfos) {
    withdrawValue = {
      pCoId: requestInfos.qusrCompId,
      pCreateEmpId: requestInfos.qusrId,
      pApprId: requestInfos.srId,
      pApprDetailId: waitingPersonInfo?.apprDetailId ?? '0',
    }
  }

  //승인, 반려
  let agreeValue = {}
  let returnValue = {}
  const [resultAgree, { isLoading: agreeIsLoading }] = useApprAgreeMutation()
  const [resultReturn, { isLoading: returnIsLoading }] = useApprReturnMutation()
  const [opinion, setOpinion] = useState()
  if (inProgressPersonInfo && requestInfos) {
    returnValue = {
      pCoId: requestInfos.qusrCompId,
      pCreateEmpId: requestInfos.qusrId,
      pApprId: requestInfos.srId,
      pDocId: requestInfos.srId,
      pApprDetailId: inProgressPersonInfo.apprDetailId,
      pApprTy: inProgressPersonInfo.apprTy,
      pReturnDtlId: '0',
      pReturnEmpId: inProgressPersonInfo.userId,
      pReturnDepId: inProgressPersonInfo.deptId,
      pApprReply: opinion,
      pApprEmpCoId: inProgressPersonInfo.coId,
      pApprEmpId: inProgressPersonInfo.userId,
      pCurStatus: '4',
    }
    agreeValue = {
      pCoId: requestInfos.qusrCompId,
      pCreateEmpId: requestInfos.qusrId,
      pApprId: requestInfos.srId,
      pDocId: requestInfos.srId,
      pApprDetailId: inProgressPersonInfo.apprDetailId,
      pApprTy: inProgressPersonInfo.apprTy,
      pApprReply: opinion,
      pApprEmpCoId: inProgressPersonInfo.coId,
      pApprEmpId: inProgressPersonInfo.userId,
      pCurStatus: '5',
    }
  }

  const handleChange = e => {
    const regExp =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    const enteredInput = e.target

    if (regExp.test(enteredInput.value)) {
      enteredInput.value = enteredInput.value.replace(regExp, '')
      setOpinion(enteredInput.value)
    } else {
      setOpinion(e.target.value)
    }
  }

  const [isShowWithDrawAlert, setIsShowWithDrawAlert] = useState(false)
  const [isShowAgreeAlert, setIsShowAgreeAlert] = useState(false)
  const [isShowReturnAlert, setIsShowReturnAlert] = useState(false)
  const [isShowWithDrawCompleteAlert, setIsShowWithDrawCompleteAlert] =
    useState(false)
  const [isShowAgreeReturnCompleteAlert, setIsShowAgreeReturnCompleteAlert] =
    useState(false)
  const [isShowWithDrawFailAlert, setIsShowWithDrawFailAlert] = useState(false)
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [isShowOpinionErrorAlert, setIsShowOpinionErrorAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [isShowNotInProgressAlert, setIsShowNotInProgressAlert] =
    useState(false)

  //회수 진행
  const withDrawHandler = async () => {
    try {
      const response = await resultWithDraw(withdrawValue).unwrap()
      if (response.msg === 'error') {
        setAlertMsg('차상위결재자가 결재를 하였으므로 회수할 수 없습니다.')
        onChangeWithDrawAlertState(false)
        onFailWithDrawAlertState(true)
        return false
      } else {
        setAlertMsg('회수 처리가 완료되었습니다.')
        onCompleteWithDrawAlertState(true)
      }
    } catch (err) {
      setAlertMsg('회수 처리가 실패했습니다. 목록으로 이동합니다.')
      onChangeWithDrawAlertState(false)
      onErrorAlertState(true)
    }
  }

  //승인 진행
  const agreeHandler = async () => {
    try {
      const data = await resultAgree(agreeValue).unwrap()

      if (data.msg === 'notInProgressError') {
        setAlertMsg('이미 회수되었거나 결재한 문서입니다. 목록으로 이동합니다.')
        onChangeAgreeAlertState(false)
        onNotInProgressAlertState(true)
      } else {
        setAlertMsg('승인 처리가 완료되었습니다. 목록으로 이동합니다.')
        onCompleteAgreeReturnAlertState(true)
      }
    } catch (err) {
      setAlertMsg('승인 처리가 실패했습니다. 목록으로 이동합니다.')
      onChangeAgreeAlertState(false)
      onNotInProgressAlertState(false)
      onErrorAlertState(true)
    }
  }

  //반려 진행
  const returnHandler = async () => {
    try {
      const data = await resultReturn(returnValue).unwrap()

      if (data.msg === 'notInProgressError') {
        setAlertMsg('이미 회수되었거나 결재한 문서입니다. 목록으로 이동합니다.')
        onChangeReturnAlertState(false)
        onNotInProgressAlertState(true)
      } else {
        setAlertMsg('반려 처리가 완료되었습니다. 목록으로 이동합니다.')
        onCompleteAgreeReturnAlertState(true)
      }
    } catch (err) {
      setAlertMsg('반려 처리가 실패했습니다. 목록으로 이동합니다.')
      onChangeReturnAlertState(false)
      onNotInProgressAlertState(false)
      onErrorAlertState(true)
    }
  }

  const onChangeWithDrawAlertState = useCallback(value => {
    setIsShowWithDrawAlert(value)
  }, [])

  const onChangeAgreeAlertState = useCallback(value => {
    setIsShowAgreeAlert(value)
  }, [])

  const onChangeReturnAlertState = useCallback(value => {
    setIsShowReturnAlert(value)
  }, [])

  const onCompleteWithDrawAlertState = useCallback(value => {
    setIsShowWithDrawCompleteAlert(value)
  }, [])

  const onCompleteAgreeReturnAlertState = useCallback(value => {
    setIsShowAgreeReturnCompleteAlert(value)
  }, [])

  const onFailWithDrawAlertState = useCallback(value => {
    setIsShowWithDrawFailAlert(value)
  }, [])

  const onErrorAlertState = useCallback(value => {
    setIsShowErrorAlert(value)
  }, [])

  const onOpinionErrorAlertState = useCallback(value => {
    setIsShowOpinionErrorAlert(value)
  })

  const onNotInProgressAlertState = useCallback(value => {
    setIsShowNotInProgressAlert(value)
  })

  useEffect(() => {
    setCurrentMenu(requestType)
  }, [requestType, setCurrentMenu])

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div className='bg-blue-100 pb-8'></div>
        <div className='bg-blue-100 pb-8'>
          <OrderApproval orderData={newOrderData} />
        </div>
      </div>

      <div className='bg-blue-100'>
        <div className='bg-white pl-16 pr-16 pt-32'>
          <h2
            className='pb-12'
            style={{ borderBottom: '1px solid #f1f1f1' }}>
            요청 내용
          </h2>
        </div>

        {information}

        {inProgressPersonInfo?.userId.localeCompare(userId, 'en', {
          sensitivity: 'base',
        }) === 0 && (
          <div className='request__card'>
            <div className='request__card-item pt-0'>
              <span>의견</span>
              <Input
                type='textarea'
                className='mt-5'
                name='pApprReply'
                onChange={handleChange}
              />
            </div>
          </div>
        )}

        <div className='pb-32 pl-16 pr-16 pt-24'>
          {firstApprover?.admitStatus == '2' &&
            contents?.qusrId.localeCompare(userId, 'en', {
              sensitivity: 'base',
            }) === 0 && (
              <Button
                type='btn'
                onClick={() => {
                  setAlertMsg(
                    '다음 결재자의 문서열람 여부와 관계없이 회수처리됩니다. 회수하시겠습니까?'
                  )
                  onErrorAlertState(false)
                  onChangeWithDrawAlertState(true)
                }}
                overrideStyle={{
                  width: '100%',
                  height: '50px',
                }}>
                회수
              </Button>
            )}

          {inProgressPersonInfo?.userId.localeCompare(userId, 'en', {
            sensitivity: 'base',
          }) === 0 && (
            <div className='d-flex pt-24'>
              <Button
                type='btn'
                overrideStyle={{
                  width: '50%',
                  height: '50px',
                }}
                onClick={() => {
                  if (!opinion) {
                    setOpinion('')
                  }
                  setAlertMsg('승인하시겠습니까?')
                  onErrorAlertState(false)
                  onChangeAgreeAlertState(true)
                }}>
                승인
              </Button>

              <Button
                type='btn'
                overrideStyle={{
                  width: '50%',
                  height: '50px',
                }}
                onClick={() => {
                  if (!opinion) {
                    setAlertMsg('의견을 입력해주세요.')
                    onChangeReturnAlertState(false)
                    onOpinionErrorAlertState(true)
                    return
                  } else {
                    setAlertMsg('반려하시겠습니까?')
                    onOpinionErrorAlertState(false)
                    onChangeReturnAlertState(true)
                  }
                }}>
                반려
              </Button>
            </div>
          )}
        </div>
      </div>
      <Alert
        isShow={isShowWithDrawAlert}
        type='confirm'
        content={`${alertMsg}`}
        onClose={() => onChangeWithDrawAlertState(false)}
        onConfirm={withDrawHandler}
      />
      <Alert
        isShow={isShowAgreeAlert}
        type='confirm'
        content={`${alertMsg}`}
        onClose={() => onChangeAgreeAlertState(false)}
        onConfirm={agreeHandler}
      />
      <Alert
        isShow={isShowReturnAlert}
        type='confirm'
        content={`${alertMsg}`}
        onClose={() => onChangeReturnAlertState(false)}
        onConfirm={returnHandler}
      />
      <Alert
        isShow={isShowWithDrawCompleteAlert}
        type='confirm'
        hideClose
        content={`${alertMsg}`}
        onConfirm={() => window.location.replace('/approval/docs-written')}
        onClose={() => window.location.replace('/approval/docs-written')}
      />
      <Alert
        isShow={isShowAgreeReturnCompleteAlert}
        type='confirm'
        hideClose
        content={`${alertMsg}`}
        onConfirm={() => window.location.replace('/approval/docs-approved')}
        onClose={() => window.location.replace('/approval/docs-approved')}
      />
      <Alert
        isShow={isShowWithDrawFailAlert}
        type='confirm'
        hideClose
        content={`${alertMsg}`}
        onConfirm={() => onFailWithDrawAlertState(false)}
        onClose={() => onFailWithDrawAlertState(false)}
      />
      <Alert
        isShow={isShowErrorAlert}
        type='error'
        content={`${alertMsg}`}
        hideClose
        onClose={() => window.location.replace('/approval/docs-for-approval')}
        onConfirm={() => window.location.replace('/approval/docs-for-approval')}
      />
      <Alert
        isShow={isShowOpinionErrorAlert}
        type='error'
        content={`${alertMsg}`}
        hideClose
        onClose={() => onOpinionErrorAlertState(false)}
        onConfirm={() => onOpinionErrorAlertState(false)}
      />
      <Alert
        isShow={isShowNotInProgressAlert}
        type='confirm'
        content={`${alertMsg}`}
        hideClose
        onClose={() => window.location.replace('/approval/docs-for-approval')}
        onConfirm={() => window.location.replace('/approval/docs-for-approval')}
      />
    </main>
  )
}
