import { createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'

export interface SatisfactionResponse {
  stftRt: string
  stftRtOpnnCn: string
  srId: string
}

const responseListAdapter = createEntityAdapter()

const initialResponseList = responseListAdapter.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getResponseList: builder.query({
      query: params => ({
        url: '/approval/serviceresponse/satisfactionList',
        params,
      }),
      transformResponse: (response: any[]) => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return responseListAdapter.setAll(initialResponseList, results)
      },
      providesTags: ['ResponseList'],
    }),
    addResponse: builder.mutation<
      SatisfactionResponse,
      Partial<SatisfactionResponse>
    >({
      query: body => ({
        url: '/approval/serviceresponse/saveStft',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(body)).toString(),
      }),
      invalidatesTags: (result, error, body) => [
        { type: 'AddResponse', id: body.srId },
      ],
    }),
  }),
})

export const { useGetResponseListQuery, useAddResponseMutation } =
  extendedApiSlice
