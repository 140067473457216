import { Link } from 'react-router-dom'
import RequestContent from '../components/common/TheRequestContent'
import { useLoaderData, useOutletContext } from 'react-router-dom'
import OrderApproval from '../components/common/TheOrderApproval'
import Card from '../components/common/TheCard'
import Button from '../components/common/TheButton'

export default function Details() {
  const outletProps = useOutletContext()
  const srId = useLoaderData()

  const item = {
    code: 'simple',
    code_number: 'SR05000003590',
    state: 'success',
    title: 'ITSM 모바일 채널을 만들어주세요.',
    content:
      '요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.',
    requestService: 'SharedService > U.STRA ITSM > ITSM',
    requestDate: '2023-04-01 09:30',
    completeHopeDate: '2023-04-01 09:30',
    completeDate: '2023-04-01 09:30',
    manager: '홍길동',
  }

  const orderData = [
    {
      stepType: 'approval',
      stepText: '승인',
      status: '작성자',
      name: '홍길동 팀원',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'proceed',
      stepText: '진행중',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리가나다라마바사',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'reject',
      stepText: '반려',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리',
      date: '2022-07-18 15:35',
    },
  ]

  const processingItem = [
    {
      title: '작업내용',
      content: '요청사항을 처리완료 하였습니다.',
    },
  ]

  // console.log(srId);
  // console.log(outletProps.currentMenu);
  return (
    <main className='content'>
      <div className='bg-blue-100 pb-8 pt-60'>
        <OrderApproval orderData={orderData} />
      </div>
      <div className='mt-30 bg-blue-100'>
        <RequestContent item={item} />

        {/* 아래 컴포넌트로 사용 */}
        {/* <div className='bg-blue-100 pt-24 pb-24 pl-16 pr-16'>
          <div className='white__card'> 
            <span className="white__card-title">고객요청 답변내용</span>
            <span className="white__card-content">이길동 매니저님께서 요청하신 내용을 처리 완료되었습니다.</span>
          </div>
          <div className='white__card'> 
            <span className="white__card-title">반려의견</span>
            <span className="white__card-content">김길동님 요청은 반려처리 하겠습니다.</span>
          </div>
        </div> */}

        <div className='pb-24 pl-16 pr-16 pt-24'>
          <Card
            title='처리내용'
            items={processingItem}>
            <Link to='/ui-page/processing'>
              <Button
                type='btn'
                variant='secondary'
                overrideStyle={{
                  width: '100%',
                  height: '46px',
                  marginTop: '14px',
                  marginLeft: 0,
                  fontSize: '15px',
                  color: '#333333',
                  border: '1px solid #cccccc',
                }}>
                작업 처리내역
              </Button>
            </Link>
          </Card>
          <Card
            title='고객요청 답변내용'
            content='이길동 매니저님께서 요청하신 내용을 처리 완료되었습니다.'
          />
          <Card
            title='반려의견'
            content='김길동님 요청은 반려처리 하겠습니다.'
          />
        </div>
      </div>
    </main>
  )
}
