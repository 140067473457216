import { useEffect } from 'react'
import Button from './TheButton'

const Alert = ({
  isShow = false,
  type = 'custom',
  title,
  content,
  hideClose = false,
  onClose,
  onConfirm,
}) => {
  const alertType = {
    warning: '경고/삭제',
    error: '에러',
    confirm: '확인/알림',
  }

  useEffect(() => {
    document.body.style.overflow = isShow ? 'hidden' : null
  }, [isShow])

  return (
    <div className={`alert__background ${!!isShow ? 'show' : ''}`}>
      <div className={`alert ${type}`}>
        <div
          className={`alert__header ${type === 'custom' ? 'is-border' : ''}`}>
          <span className='alert__title'>
            {type === 'custom' ? title : alertType[type]}
          </span>
          <button
            onClick={onClose}
            className='alert__header__close'
          />
        </div>

        <div className={`alert__body ${type}`}>
          {/* 경고/삭제 */}
          {type === 'warning' && !content && (
            <span>해당 항목을 삭제하시겠습니까?</span>
          )}
          {/* 에러 */}
          {type === 'error' && !content && <span>잘못 입력하셨습니다.</span>}
          {/* 확인/알림 */}
          {type === 'confirm' && !content && (
            <span>
              서비스 요청이 완료되었습니다. <br /> 목록으로 이동합니다.
            </span>
          )}

          {(type === 'custom' || !!content) && <span>{content}</span>}
        </div>

        <div className='alert__footer'>
          {(type === 'warning' || !hideClose) && (
            <Button
              variant='secondary'
              overrideStyle={{
                color: '#333333',
                border: '1px solid #cccccc',
              }}
              onClick={onClose}>
              취소
            </Button>
          )}
          <Button onClick={onConfirm}>
            {type === 'warning' ? '삭제' : '확인'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Alert
