import { Link } from 'react-router-dom'

const classNames = (...classes) => classes.filter(Boolean).join(' ')

export default function SidebarMenu({ menuOpen, handleMenuOpen, navigation }) {
  // console.log(navigation);
  return (
    <div className='menu'>
      <nav className='menu__inner'>
        <ul className='menu__list'>
          {navigation.map(item => (
            <li
              className='menu__item'
              key={item.id}>
              <button
                type='button'
                className='menu__link'>
                {item.name}
              </button>
              <ul className='menu__subList'>
                {item.childNavigation.map(chlid => (
                  <li
                    className='menu__subItem'
                    key={chlid.id}>
                    <Link
                      to={chlid.to}
                      className={classNames(
                        item.current
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'menu__subLink group flex items-center rounded-md px-2 py-2 text-base font-medium'
                      )}
                      onClick={() => handleMenuOpen(false)}>
                      {/* {t(chlid.name)} */}
                      {chlid.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}
