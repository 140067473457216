import Code from './TheCode'
import { Link } from 'react-router-dom'
import { decode } from 'html-entities'

const CodeList = ({
  list,
  buttonType = null,
  onlyDate = false,
  onlyDateManager = false,
  onlyAddReq = false,
}) => {
  return (
    <>
      {list.length === 0 ? (
        <div className='no__list'>작성한 문서가 없습니다.</div>
      ) : (
        list.map((item, index) => (
          <Link
            key={index}
            to={`${item.linkTo}`}
            className={!!buttonType ? `is-${buttonType}` : ''}>
            <div className='code__list'>
              {item.code && (
                <>
                  <Code
                    code={item.code}
                    code_number={item.code_number}
                    state={item.state}
                  />
                  <div className='code__list-content pb-6'>
                    {decode(item.content)}
                  </div>
                </>
              )}

              {/* 추가요청 */}
              {item.AddReqprocessingNumber && (
                <>
                  <div className='code__item'>
                    <div className={`d-flex ${item.AddReqType}`}>
                      <i className='code__icon'></i>
                      <span className='code__text'>
                        {item.AddReqprocessingNumber}
                      </span>
                      <span>{item.AddReqreqSe}</span>
                    </div>
                  </div>
                  <div className='code__list-content pb-6'>
                    {item.AddReqcontent}
                  </div>
                </>
              )}
              {/*  */}

              {/* 작업 처리내역 */}
              {item.processingNumber && (
                <>
                  <span className='code__list-processing'>
                    <em>{item.processingNumber}</em>차 처리
                  </span>
                  <div className='code__list-content'>
                    {decode(item.content)}
                  </div>
                </>
              )}
              {/*  */}

              {!!onlyDate ? (
                <div className='code__list-bottom'>{item.date}</div>
              ) : !!onlyDateManager ? (
                <div className='code__list-bottom2'>
                  <span>{item.date}</span>
                  <span>{item.manager}</span>
                </div>
              ) : !!onlyAddReq ? (
                <div className='code__list-bottom'>
                  <span>{item.AddReqdate}</span>
                  <span>{item.AddReqmanager}</span>
                </div>
              ) : (
                <>
                  {item.code !== 'deployment' && !!item.classification && (
                    <div className='code__list-bottom'>
                      <span>요청분류</span>
                      <span>{item.classification}</span>
                    </div>
                  )}

                  {item.reqManager && (
                    <div className='code__list-bottom'>
                      <span>요청자</span>
                      <span>{item.reqManager}</span>
                    </div>
                  )}

                  <div className='code__list-bottom'>
                    <span>요청일시</span>
                    <span>{item.date}</span>
                  </div>
                  {item.manager && (
                    <div className='code__list-bottom'>
                      <span>처리자</span>
                      <span>{item.manager}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </Link>
        ))
      )}
    </>
  )
}

export default CodeList
