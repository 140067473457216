import { apiSlice } from '../../app/services/apiSlice'

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getmembProcChart: builder.query({
      query: params => ({
        url: '/approval/servicedashboard',
        params,
      }),
      providesTags: ['membProcChart'],
    }),
    getDayReqRat: builder.query({
      query: params => ({
        url: '/approval/servicedashboard',
        params,
      }),
      providesTags: ['DayReqRat'],
    }),
  }),
})

export const { useGetmembProcChartQuery, useGetDayReqRatQuery } =
  extendedApiSlice
