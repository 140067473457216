const Code = ({ code, code_number, state }) => {
  let reqType
  switch (code) {
    case '단순요청':
      reqType = 'simple'
      break
    case '장애요청':
      reqType = 'error'
      break
    case '변경요청':
      reqType = 'change'
      break
    case '문제/개선':
      reqType = 'problem'
      break
    case '릴리스':
      reqType = 'deployment'
      break
    default:
      reqType = ''
      break
  }

  let statusCode
  switch (state?.trim()) {
    case '승인':
      statusCode = 'success'
      break
    case '반려':
      statusCode = 'progress'
      break
    default:
      statusCode = ''
      break
  }

  return (
    <div className='code__item'>
      <div className={`d-flex ${reqType}`}>
        <i className='code__icon'></i>
        <span className='code__text'>{code}</span>
        {!!code_number && <span className='code__number'> {code_number} </span>}
      </div>
      {!!state && (
        <div className={`code__state ${statusCode}`}>
          <span>{state}</span>
        </div>
      )}
    </div>
  )
}

export default Code
