import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

export default function Header({ currentMenu, menuOpen, handleMenuOpen }) {
  const location = useLocation()
  const { user } = useAuth()

  const pathname = [
    '/',
    '/login',
    '/docs-written',
    '/docs-for-approval',
    '/docs-approved',
  ]
  const isMainPage = pathname.includes(location.pathname)
  const [shadowStyle, setShadowStyle] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY >= 1) {
      setShadowStyle({ boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)' })
    } else {
      setShadowStyle({})
    }
  }

  return (
    <header
      className='header'
      style={shadowStyle}>
      {isMainPage ? (
        <div className='headerUser'>
          <span className='headerUser__icon'></span>
          <span className='headerUser__team'>안녕하세요!</span>
          <span className='headerUser__name'>
            <em>{user.name}</em>님
          </span>
        </div>
      ) : (
        <div className='headerMenu__status'>
          {/* {isDynamicPath && ( */}
          <button
            className='go__back'
            onClick={() => navigate(-1)}></button>
          {/* )} */}
          <strong>{currentMenu}</strong>
        </div>
      )}
      <button
        className='gnbBtn'
        data-modalopen='open'
        onClick={() => handleMenuOpen(true)}>
        gnb
      </button>
    </header>
  )
}
