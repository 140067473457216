import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import expireReducer from 'redux-persist-expire'

import { apiSlice } from './services/apiSlice'
import { authSlice } from '../features/auth/authSlice'
import { domainSlice } from '../features/auth/domainSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  transforms: [
    expireReducer('user', {
      expireSeconds: 60 * 720, // 720은 store에서 불러와야 함.
      autoExpire: true,
    }),
  ],
}

const auth = persistReducer(persistConfig, authSlice.reducer)

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth,
    domain: domainSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }).concat(apiSlice.middleware),
})

export let persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
