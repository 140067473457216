import { Link } from 'react-router-dom'
import Button from '../components/common/TheButton'
import Card from '../components/common/TheCard'
import RequestContent from '../components/common/TheRequestContent'

export default function SatisfactionDetail() {
  const item = {
    code: '단순요청',
    code_number: 'SR05000003590',
    state: '승인',
    title: 'ITSM 모바일 채널을 만들어주세요.',
    content:
      '요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.',
    requestService: 'SharedService > U.STRA ITSM > ITSM',
    requestDate: '2023-04-01 09:30',
    completeHopeDate: '2023-04-01 09:30',
    completeDate: '2023-04-01 09:30',
    manager: '홍길동',
  }

  const processingItem = [
    {
      title: '작업내용',
      content: '요청사항을 처리완료 하였습니다.',
    },
  ]

  return (
    <main className='content'>
      <div className='mt-60 bg-blue-100'>
        <RequestContent item={item} />

        <div className='pt-24 pb-24 pl-16 pr-16'>
          <Card
            title='처리내용'
            items={processingItem}>
            <Link to='/ui-page/processing'>
              <Button
                type='btn'
                variant='secondary'
                overrideStyle={{
                  width: '100%',
                  height: '46px',
                  marginTop: '14px',
                  marginLeft: 0,
                  fontSize: '15px',
                  color: '#333333',
                  border: '1px solid #cccccc',
                }}>
                작업 처리내역
              </Button>
            </Link>
          </Card>

          <Card
            title='고객요청 답변내용'
            content='이길동 매니저님께서 요청하신 내용을 처리 완료되었습니다.'
          />
          <Card
            title='반려의견'
            content='김길동님 요청은 반려처리 하겠습니다.'
          />
        </div>
      </div>
    </main>
  )
}
