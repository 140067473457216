import { Link, useNavigate, useRouteError } from 'react-router-dom'
import Button from '../../components/common/TheButton'

export default function ErrorPage() {
  const error = useRouteError()
  const navigate = useNavigate()
  // console.log(error)

  return (
    <div className='error__page'>
      <h3 className={`error${error?.status}`}> {error?.status} Error</h3>
      <p>
        {error?.status === 200
          ? '데이터가 없습니다.'
          : error?.status === 400
          ? '잘못된 요청입니다.'
          : error?.status === 401
          ? '권한이 없습니다.'
          : error?.status === 404
          ? '잘못된 주소입니다.'
          : error?.status === 500
          ? '서버에 오류가 있습니다.'
          : ''}
      </p>
      <div className='error__pageBtn'>
        <Button
          type='btn'
          variant='secondary'
          overrideStyle={{
            width: '25%',
            height: '42px',
            fontSize: '15px',
            color: '#333333',
            border: '1px solid #cccccc',
          }}
          onClick={() => navigate(-1)}>
          뒤로가기
        </Button>
        <Link
          className='btn is-primary'
          style={{
            width: '25%',
            height: '42px',
            fontSize: '15px',
          }}
          to={'/'}>
          홈으로
        </Link>
      </div>
    </div>
  )
}
