import { createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'

const reqTypeAdapter = createEntityAdapter()
const reqClientTypeAdapter = createEntityAdapter()
const reqAdapter = createEntityAdapter()

const initialReqType = reqTypeAdapter.getInitialState()
const initialClientReqType = reqClientTypeAdapter.getInitialState()
const initialReq = reqAdapter.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getReqType: builder.query({
      query: body => ({
        url: '/api/setcmmcode',
        method: 'POST',
        body,
      }),
      transformResponse: response => {
        const [{ cmmCodList }] = response
        let results = cmmCodList.map((result, index) => {
          return {
            id: index + 1,
            ...result,
          }
        })
        results = [{ id: 0, cmmCodCl: '', cmmCodNm: '전체' }, ...results]
        return reqTypeAdapter.setAll(initialReqType, results)
      },
      providesTags: ['ReqType'],
    }),
    getClientReqType: builder.query({
      query: params => ({
        url: '/approval/serviceprogress/getReqCl',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        // results = [{ id: 0, reqCl: '', reqClNm: '전체' }, ...results]
        return reqTypeAdapter.setAll(initialClientReqType, results)
      },
      providesTags: ['ClientReqType'],
    }),
    getReq: builder.query({
      query: params => ({
        url: '/approval/serviceprogress/list',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return reqTypeAdapter.setAll(initialReq, results)
      },
      providesTags: ['Req'],
    }),
  }),
})

export const { useGetReqTypeQuery, useGetClientReqTypeQuery, useGetReqQuery } =
  extendedApiSlice
