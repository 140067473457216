import { useLoaderData, useOutletContext } from 'react-router-dom'
import Button from '../components/common/TheButton'
import Input from '../components/common/TheInput'
import OrderApproval from '../components/common/TheOrderApproval'
import ProgressApproval from '../components/common/TheProgressApproval'
import RequestContent from '../components/common/TheRequestContent'

export default function ApprovalDetail() {
  const outletProps = useOutletContext()
  const srId = useLoaderData()
  // console.log('라우터에서 받아온 페이지 파라미터: '+srId);

  const progressData = [
    {
      progress: 'did',
      status: '요청접수',
    },
    {
      progress: 'do',
      status: '1차 처리',
    },
    {
      progress: 'will',
      status: '2차 처리 요청접수',
    },
    {
      progress: 'will',
      status: '3차 처리 요청접수',
    },
    {
      progress: 'will',
      status: '4차 처리 요청접수',
    },
  ]

  const orderData = [
    {
      stepType: 'approval',
      stepText: '승인',
      status: '작성자',
      name: '홍길동 팀원',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'proceed',
      stepText: '진행중',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리가나다라마바사',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'reject',
      stepText: '반려',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리',
      date: '2022-07-18 15:35',
    },
  ]

  const item = {
    code: '단순요청',
    title: 'ITSM 모바일 채널을 만들어주세요.',
    content:
      '요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.',
    requestService: 'SharedService > U.STRA ITSM > ITSM',
    completeHopeDate: '2023-04-01 09:30',
    addContent: '보안내용 조회 합니다.',
    attatchFile: '변경요청.xlsx',
  }

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div className='bg-blue-100 pb-8'>
          <ProgressApproval progressData={progressData} />
        </div>
        <div className='bg-blue-100 pb-8'>
          <OrderApproval orderData={orderData} />
        </div>
      </div>
      <div className='bg-blue-100'>
        <div className='bg-white pt-32 pl-16 pr-16 pb-12'>
          <h2
            className='pb-12'
            style={{ borderBottom: '1px solid #f1f1f1' }}>
            요청 내용
          </h2>
        </div>

        <RequestContent item={item} />

        <div className='request__card'>
            <div className='request__card-item pt-0'>
              <span>의견</span>
              <Input
                type='textarea'
                className='mt-5'
                name='pApprReply'
              />
            </div>
          </div>

        <div className='pt-24 pb-32 pl-16 pr-16'>
          <Button
            type='btn'
            overrideStyle={{
              width: '100%',
              height: '50px',
            }}>
            회수
          </Button>

          <div className='d-flex mt-64'>
            <Button
              type='btn'
              overrideStyle={{
                width: '50%',
                height: '50px',
              }}>
              승인
            </Button>
            <Button
              type='btn'
              overrideStyle={{
                width: '50%',
                height: '50px',
              }}>
              반려
            </Button>
          </div>
        </div>
      </div>
    </main>
  )
}
