const Table = ({ headerList, data, width }) => {
  return (
    <table
      className='basic__table'
      style={{ width: !!width ? width : '' , tableLayout: 'fixed'}}>
      <colgroup>
        {headerList?.map((c_header, c_index) => (
          <col
            key={c_index}
            style={{
              width: !!c_header.width ? c_header.width : 'auto',
            }}
          />
        ))}
      </colgroup>
      <thead>
        <tr>
          {headerList?.map((h_header, h_index) => (
            <th key={h_index}>{h_header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data?.map((_data, index) => (
            <tr key={index}>
              {headerList?.map((b_header, b_index) => (
                <td style={{ wordBreak: 'break-all' }} key={b_index}>{_data[b_header.value]}</td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default Table;