import { createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'

const simpleHistoryAdapter = createEntityAdapter()

const initialSimpleHistory = simpleHistoryAdapter.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getSimpleHistory: builder.query({
      query: params => ({
        url: '/approval/servicedesksmpl/popup/smplReqHdlList',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return simpleHistoryAdapter.setAll(initialSimpleHistory, results)
      },
      providesTags: ['WorkHistory'],
    }),
  }),
})

export const { useGetSimpleHistoryQuery } = extendedApiSlice
