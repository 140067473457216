import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { register } from 'swiper/element/bundle'
import Alert from '../components/common/TheAlert'
import BarChart from '../components/common/TheBarChart'
import CodeList from '../components/common/TheCodeList'
import DoughnutChart from '../components/common/TheDoughnutChart'
import ScrollTop from '../components/common/TheScrollTop'
import { Tab, Tabs } from '../components/common/TheTab'

register()
export default function Dashboard() {
  const [isShowAlert, setIsShowAlert] = useState(false)
  const todayTotal = {
    request: 6,
    process: 1,
    processingRate: 16,
  }

  const doughnutData = [
    todayTotal.processingRate,
    100 - todayTotal.processingRate,
  ]
  const barLabel = ['김병민', '박민석', '박주현', '최학준', '김기범']
  const barData = [27, 23, 20, 14, 14]

  const onChangeAlertState = useCallback(value => {
    setIsShowAlert(value)
  }, [])

  const [progress, setProgress] = useState()
  const list = [
    {
      code: '단순요청',
      content: '담당자를 변경해주세요.',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '변경요청',
      content: '시스템 설치에 관한 문의드립니다..',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '문제/개선',
      content: '문제 해결',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '장애요청',
      content: '문제 해결',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      code: '릴리스',
      content: '릴리즈 요청사항입니다.',
      classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
  ]

  return (
    <main className='content'>
      {/* Swiper Version */}
      <swiper-container
        class='chart__block'
        slides-per-view='2'
        slides-per-group='1'
        css-mode
        space-between='16'>
        <swiper-slide>
          <div className='chart__card'>
            <span className='chart__title'>당일 요청/처리 현황</span>
            <div className='chart__center'>
              <span>{todayTotal.processingRate}%</span>
              <span>처리율</span>
            </div>
            <div className="doughnut-chart">
              <DoughnutChart
                className='ma-auto mt-25 mb-10'
                id='exDoughnutChart'
                chartData={doughnutData}
              />
            </div>
            <div className='chart__card-bottom'>
              <div>
                <span>{todayTotal.request}</span>
                <span className='mt-2'>요청</span>
              </div>
              <div>
                <span>{todayTotal.process}</span>
                <span className='mt-2'>처리</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div className='chart__card'>
            <span className='chart__title'>담당자별 처리현황</span>
            <span
              style={{
                fontSize: '16px',
              }}>
              &nbsp;Top5
            </span>
            <BarChart
              className='ma-auto mt-3 pb-8'
              id='exBarChart'
              chartLabel={barLabel}
              chartData={barData}
            />
          </div>
        </swiper-slide>
      </swiper-container>

      {/* Scroll Version */}
      {/* <div className="chart__block">
        <div className="chart__card">
          <span className="chart__title">당일 요청/처리 현황</span>
          <div className="chart__center">
            <span>{todayTotal.processingRate}%</span>
            <span>처리율</span>
          </div>
          <DoughnutChart 
            className="mt-14 mb-10 ma-auto" 
            id="exDoughnutChart"
            chartData={doughnutData}
          />
          <div className="chart__card-bottom">
            <div>
              <span>{todayTotal.request}</span>
              <span className="mt-2">요청</span>
            </div>
            <div>
              <span>{todayTotal.process}</span>
              <span className="mt-2">처리</span>
            </div>
          </div>
        </div>

        <div className="chart__card ml-20 mr-20">
          <span className="chart__title">담당자별 처리현황</span>
          <span
            style={{
              fontSize: '16px'
            }}
          >
            &nbsp;Top5
          </span>
          <BarChart 
            className="mt-3 pb-8 ma-auto" 
            id="exBarChart"
            chartLabel={barLabel}
            chartData={barData}
          />
        </div>
      </div> */}

      <div className='pt-10 pl-16 pr-16'>
        <h2 className='pt-20 pb-24'>
          <Link
            to='/ui-page/approval'
            className='text-black-95'>
            결재 문서함 &gt;
          </Link>
        </h2>
        <Tabs activeValue={0}>
          <Tab
            label='작성한 문서'
            disabled>
            <div className='pb-50 pt-4'>
              <CodeList
                list={list}
                progress={progress}
                onlyDate
                buttonType='confirm'
              />
            </div>
          </Tab>
          <Tab label='결재할 문서 (99+)'>
            <div className='pb-50 pt-4'>
              <CodeList
                list={list}
                progress={progress}
                onlyDate
                buttonType='approval'
              />
            </div>
          </Tab>
          <Tab label='결재한 문서'>
            <div className='pb-50 pt-4'>
              <CodeList
                list={list}
                progress={progress}
                onlyDate
                buttonType='confirm'
              />
            </div>
          </Tab>
        </Tabs>
      </div>

      <ScrollTop />

      <Alert
        isShow={isShowAlert}
        type='warning'
        onClose={() => onChangeAlertState(false)}
        onConfirm={() => onChangeAlertState(false)}
      />
    </main>
  )
}
