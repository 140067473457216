import { Chart as ChartJS, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(Tooltip)

const DoughnutChart = ({
  className: parentClass,
  id,
  chartData = [], // array,
  backgroundColor,
}) => {
  // Not use Datalabel
  const options = {
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
    },
  }

  const base_data = {
    labels: false,
    datasets: [
      {
        data: chartData,
        backgroundColor: backgroundColor || ['#FFB840', '#E3E3E3'],
        cutout: 42,
        borderWidth: 0,
      },
    ],
  }

  return (
    <Doughnut
      className={parentClass}
      id={id}
      options={options}
      data={base_data}
      height='140px'
      width='144px'
      style={{ margin: 'auto' }}
    />
  )
}

export default DoughnutChart
