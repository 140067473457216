import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'
import { RootState } from '../../app/store'

const domainAdapter = createEntityAdapter()
const initialDomain = domainAdapter.getInitialState()

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDomain: builder.query<any, void>({
      query: () => ({
        url: 'system/comp/login/domain',
      }),
      transformResponse: response => {
        let results = (response as Array<any>).map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return domainAdapter.setAll(initialDomain, results)
      },
      providesTags: ['Domain'],
    }),
  }),
})

export const domainSlice = createSlice({
  name: 'domain',
  initialState: initialDomain,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      extendedApiSlice.endpoints.getDomain.matchFulfilled,
      (state, { payload }) => {
        state.ids = payload.ids
        state.entities = payload.entities
      }
    )
  },
})

export const selectDomain = (state: RootState) => state.domain

// export const selectDomainResult = extendedApiSlice.endpoints.getDomain.select()

// const selectDomainData = createSelector(
//   selectDomainResult,
//   usersResult => usersResult.data
// )

// export const { selectAll: selectDomain } = domainAdapter.getSelectors(
//   (state: RootState) => selectDomainData(state) ?? initialDomain
// )
