import { apiSlice } from '../../app/services/apiSlice'

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getRequestInfo: builder.query({
      query: params => ({
        url: '/approval/approvalbox/requestInfo',
        params,
      }),
      providesTags: ['RequestInfo'],
    }),
    getApprLines: builder.query({
      query: params => ({
        url: '/approval/approvalbox/appLine',
        params,
      }),
      providesTags: ['ApprLines'],
    }),
  }),
})

export const { useGetRequestInfoQuery, useGetApprLinesQuery } = extendedApiSlice
