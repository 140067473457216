import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectDomain } from '../auth/domainSlice'
import {
  useGetReqTypeQuery,
  useLazyGetMylistProgressQuery,
} from './mylistSlice'

import Calendar from '../../components/common/TheCalendar'
import Dropdown from '../../components/common/TheSelect'
import Button from '../../components/common/TheButton'
import Input from '../../components/common/TheInput'
import CodeList from '../../components/common/TheCodeListForRequest'
import Loader from '../../components/common/TheLoader'

export default function MyList() {
  // 회사구분
  const [company, setCompany] = useState('')
  const domains = useSelector(selectDomain)

  const domainOptions = Object.entries(domains.entities).map(
    ([ids, entities]) => ({
      label: entities.coNm,
      value: entities.coId,
    })
  )

  // 요청유형
  const [reqType, setReqType] = useState('')
  let {
    data: reqTypes = {},
    isLoading: isReqTypeLoading,
    isSuccess: isReqTypeSuccess,
  } = useGetReqTypeQuery({
    cmmCodList: [
      {
        cmmCodCl: '0175',
        companyAt: false,
        useAt: 'Y',
      },
    ],
  })

  let reqTypeOptions
  if (isReqTypeLoading) {
    reqTypeOptions = [
      {
        label: '로딩중...',
        value: '',
      },
    ]
  } else if (isReqTypeSuccess) {
    reqTypeOptions = Object.entries(reqTypes.entities).map(
      ([ids, entities]) => ({
        label: entities.cmmCodNm,
        value: entities.cmmCodCl,
      })
    )
  }

  // 요청일
  const [rangeObj, setRangeObj] = useState({})

  const onChangeRangePicker = value => {
    setRangeObj(value)
  }

  // 검색어
  const searchTypeOptions = [
    { label: '요청번호', value: '0' },
    { label: '요청자', value: '1' },
    { label: '요청제목', value: '2' },
  ]

  const [searchType, setSearchType] = useState(searchTypeOptions[0].value)
  const [inputValue, setInputValue] = useState('')

  // 더보기
  const [N, setN] = useState(10)

  const loadMore = () => {
    setN(N + 10)
  }

  const renderButton = () => {
    if (mylistProgressList.ids?.length > N) {
      return (
        <>
          <Button
            type='shuffle'
            variant='secondary'
            overrideStyle={{
              width: '100%',
              height: '46px',
              marginTop: '14px',
              marginLeft: 0,
              fontSize: '15px',
              color: '#333333',
              border: '1px solid #cccccc',
            }}
            onClick={loadMore}>
            더보기
            <span className='arrow__icon expand' />
          </Button>
        </>
      )
    }
  }

  // 조회
  const [skip, setSkip] = useState(false)

  const [
    getMyListProgress,
    {
      data: mylistProgressList = [],
      isLoading: isMyListLoading,
      isSuccess: isMyListSuccess,
      isFetching: isMyListFetching,
    },
  ] = useLazyGetMylistProgressQuery()

  const onClickSearchButton = e => {
    getMyListProgress({
      coId: company,
      searchReqTySe: reqType,
      startDate: rangeObj.start,
      endDate: rangeObj.end,
      searchType: searchType,
      searchWord: inputValue,
      perPage: 1,
      page: 1,
    })
  }

  let mylistProgressListCode
  if (isMyListLoading || isMyListFetching) {
    mylistProgressListCode = <Loader/>
  } else if (isMyListSuccess) {
    mylistProgressListCode = (
      <CodeList
        list={Object.entries(mylistProgressList.entities)
          .slice(0, N)
          .map(([ids, entities]) => {
            const linkTo = `/my-list-detail/${entities.reqTySe}/${entities.srId}`

            return {
              code: entities.reqTySeNm,
              code_number: entities.srId,
              state: entities.reqStatNm,
              content: entities.srTitlNm,
              date: entities.reqDt,
              reqManager: entities.qusrNm,
              linkTo: linkTo,
            }
          })}
      />
    )
  }

  useEffect(() => {
    company || setCompany(domainOptions[0]?.value)
    if (company && !skip) {
      try {
        getMyListProgress({
          coId: company,
          searchReqTySe: reqType,
          startDate: rangeObj.start,
          endDate: rangeObj.end,
          searchType: searchType,
          searchWord: inputValue,
          perPage: 1,
          page: 1,
        }).unwrap()
        setSkip(true)
      } catch (err) {}
    }
  }, [
    company,
    domainOptions,
    skip,
    getMyListProgress,
    reqType,
    rangeObj,
    searchType,
    inputValue,
  ])

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div
          className='blue__card bg-blue-100'
          style={{ padding: '32px 16px' }}>
          <Dropdown
            label='회사구분'
            options={domainOptions}
            onChange={e => setCompany(e.curr.value)}
          />

          <Dropdown
            label='요청유형'
            options={reqTypeOptions}
            onChange={e => setReqType(e.curr.value)}
          />

          <Calendar
            id='range1'
            label='요청일'
            format='yyyy-MM-dd'
            onChange={onChangeRangePicker}
          />

          <span className='text-primary fw-600'>검색어</span>
          <div className='d-flex'>
            <Dropdown
              id='select_search'
              options={searchTypeOptions}
              onChange={e => setSearchType(e.curr.value)}
              width='132px'
            />
            <Input
              className='mb-20 ml-4 mt-6'
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </div>

          <Button
            type='shuffle'
            overrideStyle={{
              width: '100%',
              height: '42px',
              marginTop: '16px',
              fontSize: '15px',
            }}
            onClick={onClickSearchButton}>
            조회
          </Button>
        </div>

        <div className='pb-40 pl-16 pr-16 pt-32'>
          <div className='d-flex align-items-center mb-12'>
            <h2 className='fw-600 text-primary pr-4'>서비스 요청 목록</h2>
            <span
              className='fw-400 text-black'
              style={{ fontSize: '15px' }}>
              {mylistProgressList?.ids?.length || 0}건
            </span>
          </div>

          {mylistProgressListCode}

          {renderButton()}
        </div>
      </div>
    </main>
  )
}
