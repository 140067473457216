import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import _ from 'lodash'
import Button from '../components/common/TheButton'
import Code from '../components/common/TheCode'
import Input from '../components/common/TheInput'
import Radio from '../components/common/TheRadio'
import ScrollTop from '../components/common/TheScrollTop'
import Dropdown from '../components/common/TheSelect'

export default function Satisfaction() {
  const options = [
    { label: '선택옵션1', value: '선택옵션1' },
    { label: '선택옵션2', value: '선택옵션2' },
    { label: '선택옵션3', value: '선택옵션3' },
    { label: '선택옵션4', value: '선택옵션4' },
  ]

  const [company, setCompany] = useState(options[0].value)
  const [requestType, setRequestType] = useState(options[0].value)
  const [searchType, setSearchType] = useState(options[0].value)
  const [inputValue, setInputValue] = useState('')
  const [list, setList] = useState([])

  useEffect(() => {
    setList([
      {
        code: '단순요청',
        code_number: 'SR05000003590',
        state: '승인',
        content: '담당자를 변경해주세요.',
        satisfaction_level: '',
        satisfaction_content: '',
      },
      {
        code: '변경요청',
        code_number: 'SR05000003590',
        state: '반려',
        content: '시스템 설치에 관한 문의드립니다..',
        satisfaction_level: '',
        satisfaction_content: '',
      },
      {
        code: '문제/개선',
        code_number: 'SR05000003590',
        state: '승인',
        content: '문제 해결',
        satisfaction_level: '',
        satisfaction_content: '',
      },
      {
        code: '장애요청',
        code_number: 'SR05000003590',
        state: '승인',
        content: '문제 해결',
        satisfaction_level: '',
        satisfaction_content: '',
      },
      {
        code: '릴리스',
        code_number: 'SR05000003590',
        state: '승인',
        content: '릴리즈 요청사항입니다.',
        satisfaction_level: '',
        satisfaction_content: '',
      },
    ])
  }, [])

  const onClickSearchButton = _ => {
    console.table([
      ['회사구분', company],
      ['요청유형', requestType],
      ['검색어 유형', searchType],
      ['검색어', inputValue],
    ])
  }

  const onClickSaveButton = item => {
    if (!item) return
    console.log('On Click Save: ', item)
  }

  const onChangeListItem = (index, value) => {
    let _item = _.cloneDeep(list)
    _item[index]['satisfaction_content'] = value
    setList(_item)
  }

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div
          className='blue__card bg-blue-100'
          style={{ padding: '32px 16px' }}>
          <Dropdown
            value={company}
            id='select_company'
            label='회사구분'
            options={options}
            onChange={e => setCompany(e.curr.value)}
          />
          <Dropdown
            value={requestType}
            id='select_type'
            label='요청유형'
            options={options}
            onChange={e => setRequestType(e.curr.value)}
          />

          <span className='text-primary fw-600'>검색어</span>
          <div className='d-flex'>
            <Dropdown
              id='select_search'
              value={searchType}
              options={options}
              onChange={e => setSearchType(e.curr.value)}
              width='132px'
            />
            <Input
              className='mt-6 mb-20 ml-4'
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </div>

          <Button
            type='btn'
            overrideStyle={{
              width: '100%',
              height: '42px',
              marginTop: '16px',
              fontSize: '15px',
            }}
            onClick={onClickSearchButton}>
            조회
          </Button>
        </div>

        <div className='search__list'>
          <div className='search__list-title'>
            <h2>만족도 조사 목록</h2>
            <span>4건</span>
          </div>

          {list.map((item, index) => (
            <div
              key={index}
              className='satisfaction__list'>
              <Link to={`${item.code_number}`}>
                <div className='satisfaction__list__top'>
                  <Code
                    code={item.code}
                    code_number={item.code_number}
                    state={item.state}
                  />
                  <div className='satisfaction__list__content'>
                    {item.content}
                  </div>
                </div>
              </Link>

              <div className='satisfaction__list__bottom'>
                <span className='text-primary fw-600'>고객만족도</span>
                <div
                  className='d-flex mt-16 mb-16'
                  style={{ flexWrap: 'wrap' }}>
                  <Radio
                    label='매우만족'
                    name='radio'
                    defaultChecked
                  />
                  <Radio
                    label='만족'
                    name='radio'
                  />
                  <Radio
                    label='보통'
                    name='radio'
                  />
                  <Radio
                    label='불만'
                    name='radio'
                  />
                  <Radio
                    label='매우불만'
                    name='radio'
                  />
                  <Radio
                    label='무응답'
                    name='radio'
                  />
                </div>
                <span className='text-primary fw-600'>만족도의견</span>
                <Input
                  type='textarea'
                  className='mt-6 mb-20'
                  value={item.satisfaction_content}
                  onChange={e => onChangeListItem(index, e.target.value)}
                />
                <Button
                  type='btn'
                  onClick={onClickSaveButton(item[index])}>
                  저장
                </Button>
              </div>
            </div>
          ))}

          <Button
            type='shuffle'
            variant='secondary'>
            더보기
            <span className='arrow__icon expand'></span>
          </Button>
        </div>
      </div>
      <ScrollTop />
    </main>
  )
}
