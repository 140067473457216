import { useCallback, useEffect, useState } from 'react'

import Dropdown from '../../components/common/TheSelect'
import Radio from '../../components/common/TheRadio'
import Button from '../../components/common/TheButton'
import Input from '../../components/common/TheInput'
import { useGetReqTypeQuery } from '../mylist/mylistSlice'
import {
  useAddResponseMutation,
  useGetResponseListQuery,
} from './responseSlice'
import RequestContent from '../../components/common/TheRequestContent'
import Card from '../../components/common/TheCard'
import { Form } from 'react-router-dom'
import Alert from '../../components/common/TheAlert'
import Loader from '../../components/common/TheLoader'

export default function ServiceResponse() {
  const [defaultPayload, setDefaultPayload] = useState({
    reqTySe: '',
    schCond: '1',
    searchword: '',
    perPage: '1',
    page: '1',
  })

  // 요청유형
  const [reqType, setReqType] = useState('')
  let {
    data: reqTypes = {},
    isLoading: isReqTypeLoading,
    isSuccess: isReqTypeSuccess,
  } = useGetReqTypeQuery({
    cmmCodList: [
      {
        cmmCodCl: '0175',
        companyAt: false,
        useAt: 'Y',
      },
    ],
  })

  let reqTypeOptions
  if (isReqTypeLoading) {
    reqTypeOptions = [
      {
        label: '로딩중...',
        value: '',
      },
    ]
  } else if (isReqTypeSuccess) {
    reqTypeOptions = Object.entries(reqTypes.entities).map(
      ([ids, entities]) => ({
        label: entities.cmmCodNm,
        value: entities.cmmCodCl,
      })
    )
  }

  // 검색어
  const searchTypeOptions = [
    { label: '요청번호', value: '1' },
    { label: '접수자', value: '2' },
    { label: '처리자', value: '3' },
    { label: '제목', value: '4' },
    { label: '내용', value: '5' },
  ]

  const [searchType, setSearchType] = useState(searchTypeOptions[0].value)
  const [inputValue, setInputValue] = useState('')

  const onClickSearchButton = e => {
    setDefaultPayload({
      reqTySe: reqType,
      schCond: searchType,
      searchword: inputValue,
      perPage: 1,
      page: 1,
    })
  }

  //조회
  const {
    data: responseList = [],
    isLoading: isResponseListLoading,
    isSuccess: isResponseListSuccess,
    isFetching: isResponseListFetching,
  } = useGetResponseListQuery(defaultPayload)

  let baseinfoList = []
  let resultList = []
  if (isResponseListSuccess && responseList.entities) {
    baseinfoList = Object.entries(responseList.entities).map(
      ([ids, entities]) => ({
        ...entities.baseinfo,
      })
    )

    //요청유형 코드에 따라 고객요청 답변내용 및 반려의견 데이터를 넣어주는 구문
    for (const [ids, entities] of Object.entries(responseList.entities)) {
      if (entities.baseinfo.reqTySe === '0215') {
        resultList.push(entities.smplresultinfo)
      } else if (entities.baseinfo.reqTySe === '0223') {
        resultList.push(entities.inciresultinfo)
      } else if (entities.baseinfo.reqTySe === '0179') {
        resultList.push(entities.resultinfo)
      } else if (entities.baseinfo.reqTySe === '1045') {
        resultList.push(entities.probresultinfo)
      } else {
        resultList.push(entities.relsPlanInfo)
      }
    }
  }

  //baseinfo와 smplresultinfo | smplresultinfo | resultinfo | probresultinfo | relsPlanInfo 병합
  const mergedList = baseinfoList.map((baseinfo, index) => {
    const result = resultList[index]

    return {
      ...baseinfo,
      ...result,
    }
  })

  let copyBaseinfoList = [...mergedList]
  let newBaseinfoList = copyBaseinfoList.map((item, index) => {
    if (item.srCnTmlSe === 'CM100602') {
      const srCn = JSON.parse(item.srCn)
      if (!srCn.ops) {
        item.srCn = srCn.map(item => {
          item.name = item.name + index
          return item
        })
      }
    }
    return {
      ...item,
      title: item.srTitlNm,
      content: item.srCn,
      srCnTmlSe: item.srCnTmlSe,
      requestService: item.servPathNm,
      requestDate: item.reqDt,
      receptionist: item.eusrNm,
      completeHopeDate: item.chgCmplHopeDtStr,
      completeDate: item.cmplDt,
      code: item.reqTyNm,
      code_number: item.srId,
      state: item.hdlStsNm,
      manager: item.hdlNm,
      managerPosition: item.hdlPosiNm,
    }
  })

  //만족도 조사
  const [
    response,
    { isLoading: isResponseLoading, isSuccess: isResponseSuccess },
  ] = useAddResponseMutation()

  //만족도 등급
  const [grade, setGrade] = useState('0254')
  const gradeHandleChange = e => {
    setGrade(e.target.value)
  }

  //만족도 의견
  const [opinion, setOpinion] = useState('')

  const handleChange = e => {
    const regExp =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    const enteredInput = e.target

    if (regExp.test(enteredInput.value)) {
      enteredInput.value = enteredInput.value.replace(regExp, '')
      setOpinion(enteredInput)
    } else {
      setOpinion(e.target.value)
    }
  }

  //만족도 페이로드
  const [formState, setFormState] = useState({
    stftRt: grade,
    stftRtOpnnCn: opinion,
    srId: '',
  })

  //더보기 버튼
  const [N, setN] = useState(1)
  const loadMore = () => {
    setN(N + 1)
  }
  const renderButton = () => {
    if (newBaseinfoList.length <= N) {
      return <></>
    } else if (newBaseinfoList.length > N) {
      return (
        <Button
          type='shuffle'
          variant='secondary'
          onClick={loadMore}>
          더보기
          <span className='arrow__icon expand'></span>
        </Button>
      )
    }
  }

  let responseListCode
  if (isResponseListLoading || isResponseListFetching) {
    responseListCode = <Loader className='loading' />
  } else if (isResponseListSuccess && newBaseinfoList.length === 0) {
    responseListCode = <div className='no__list'>작성한 문서가 없습니다.</div>
  } else if (isResponseListSuccess && newBaseinfoList.length > 0) {
    responseListCode = (
      <>
        {newBaseinfoList.slice(0, N).map((item, index) => (
          <Form key={item.srId}>
            <div className='satisfaction__list'>
              <div className='satisfaction__list__top'>
                <RequestContent item={item} />

                {!item.rclCn && !!item.ntcCn && (
                  <Card
                    title='고객요청 답변 내용'
                    content={item.ntcCn}
                  />
                )}
                {!!item.rclCn && (
                  <Card
                    title='반려의견'
                    content={item.rclCn}
                  />
                )}
              </div>

              <div className='satisfaction__list__bottom'>
                <span className='text-primary fw-600'>고객만족도</span>
                <div
                  className='d-flex mb-16 mt-16'
                  style={{ flexWrap: 'wrap' }}>
                  <Radio
                    label='매우만족'
                    name='stftRt'
                    value='0254'
                    defaultChecked
                    onClick={gradeHandleChange}
                  />
                  <Radio
                    label='만족'
                    value='0255'
                    name='stftRt'
                    onClick={gradeHandleChange}
                  />
                  <Radio
                    label='보통'
                    name='stftRt'
                    value='0256'
                    onClick={gradeHandleChange}
                  />
                  <Radio
                    label='불만'
                    name='stftRt'
                    value='0257'
                    onClick={gradeHandleChange}
                  />
                  <Radio
                    label='매우불만'
                    name='stftRt'
                    value='0258'
                    onClick={gradeHandleChange}
                  />
                  <Radio
                    label='무응답'
                    name='stftRt'
                    value='0626'
                    onClick={gradeHandleChange}
                  />
                </div>
                <span className='text-primary fw-600'>만족도의견</span>

                {isResponseLoading && <Loader className='loading' />}

                <Input
                  type='textarea'
                  className='mb-20 mt-6'
                  onChange={handleChange}
                />
                <Button
                  type='btn'
                  onClick={event => {
                    event.preventDefault()
                    setFormState({
                      stftRt: grade,
                      stftRtOpnnCn: opinion,
                      srId: item.srId,
                    })
                  }}>
                  저장
                </Button>
              </div>
            </div>
          </Form>
        ))}
        {renderButton()}
      </>
    )
  }

  //Alert
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [isShowCompleteAlert, setIsShowCompleteAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')

  const onChangeAlertState = useCallback(value => {
    setIsShowAlert(value)
  }, [])

  const onErrorAlertState = useCallback(value => {
    setIsShowErrorAlert(value)
  }, [])

  const onCompleteAlertState = useCallback(value => {
    setIsShowCompleteAlert(value)
  }, [])

  useEffect(() => {
    if (formState.stftRt !== '' && formState.srId !== '') {
      const saveResponse = () => {
        setAlertMsg('저장하시겠습니까?')
        onErrorAlertState(false)
        onChangeAlertState(true)
      }
      saveResponse()
    }
  }, [formState])

  const responseHandler = async () => {
    try {
      await response(formState).unwrap()
      setAlertMsg('만족도가 저장 되었습니다.')
      onCompleteAlertState(true)
    } catch (err) {
      setAlertMsg('문서번호가 없습니다. 관리자에게 문의해주세요.')
      onChangeAlertState(false)
      onErrorAlertState(true)
    }
  }

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div
          className='blue__card bg-blue-100'
          style={{ padding: '32px 16px' }}>
          <Dropdown
            label='요청유형'
            options={reqTypeOptions}
            onChange={e => setReqType(e.curr.value)}
          />

          <span className='text-primary fw-600'>검색어</span>
          <div className='d-flex'>
            <Dropdown
              id='select_search'
              options={searchTypeOptions}
              onChange={e => setSearchType(e.curr.value)}
              width='132px'
            />
            <Input
              className='mb-20 ml-4 mt-6'
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          </div>

          <Button
            type='btn'
            overrideStyle={{
              width: '100%',
              height: '42px',
              marginTop: '16px',
              fontSize: '15px',
            }}
            onClick={onClickSearchButton}>
            조회
          </Button>
        </div>

        <div className='search__list'>
          <div className='search__list-title'>
            <h2>만족도 조사 목록</h2>
            <span>{newBaseinfoList.length || 0}건</span>
          </div>
          {responseListCode}
        </div>
      </div>

      <Alert
        isShow={isShowAlert}
        type='confirm'
        content={`${alertMsg}`}
        onClose={() => onChangeAlertState(false)}
        onConfirm={responseHandler}
      />
      <Alert
        isShow={isShowCompleteAlert}
        type='confirm'
        hideClose
        content={`${alertMsg}`}
        onConfirm={() => window.location.reload()}
        onClose={() => window.location.reload()}
      />
      <Alert
        isShow={isShowErrorAlert}
        type='error'
        content={`${alertMsg}`}
        hideClose
        onConfirm={() => onErrorAlertState(false)}
        onClose={() => onErrorAlertState(false)}
      />
    </main>
  )
}
