import { useState } from 'react'
import { Link } from 'react-router-dom'
import Input from '../components/common/TheInput'
import Button from '../components/common/TheButton'
import Checkbox from '../components/common/TheCheckbox'

export default function Login() {
  const [idValue, setIdValue] = useState('')
  const [pwValue, setPwValue] = useState('')

  return (
    <main className='content login'>
      <h1 className='logo'>USTRA ITSM</h1>
      <div className='loginBox'>
        {/* <input type="text" placeholder="아이디" className="login__input" />
        <input type="text" placeholder="비밀번호" className="login__input" />
        <button className="btn is-primary is-large width-100">로그인</button> */}

        <Input
          className='mt-10 mb-10'
          value={idValue}
          onChange={e => setIdValue(e.target.value)}
          placeholder='아이디'
        />
        <Input
          type='password'
          className='mt-10 mb-10'
          value={pwValue}
          onChange={e => setPwValue(e.target.value)}
          placeholder='비밀번호'
          error
          errorText='아이디 혹은 비밀번호가 일치하지 않습니다.'
        />
        <Link to='/ui-page/main'>
          <Button>로그인</Button>
        </Link>
        <div className='mt-20'>
          <Checkbox label='아이디저장' />
        </div>
      </div>
      <footer className='footer'>
        <p className='footer__copyright'>
          COPYRIGHT © 2023 GS ITM. ALL RIGHTS RESERVED.
        </p>
      </footer>
    </main>
  )
}
