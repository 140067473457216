import { createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'

const addReqInfoAdapter = createEntityAdapter()
const apprLinesProcAdapter = createEntityAdapter()
const taskDetailAdapter = createEntityAdapter()

const initialAddReqInfo = addReqInfoAdapter.getInitialState()
const initialApprLinesProc = apprLinesProcAdapter.getInitialState()
const initialTaskDetail = taskDetailAdapter.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAddReqInfo: builder.query({
      query: params => ({
        url: '/approval/servicedeskcomm/additionalReqInfo',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return addReqInfoAdapter.setAll(initialAddReqInfo, results)
      },
      providesTags: ['AddReqInfo'],
    }),
    getApprLinesProc: builder.query({
      query: params => ({
        url: '/approval/servicedeskcomm/apprlines',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return apprLinesProcAdapter.setAll(initialApprLinesProc, results)
      },
      providesTags: ['ApprLinesProc'],
    }),
    getServiceResult: builder.query({
      query: params => ({
        url: '/approval/serviceresponse/detail',
        params,
      }),
      providesTags: ['ServiceResult'],
    }),
    getTaskDetail: builder.query({
      query: params => ({
        url: '/approval/servicedeskchgdev/taskDetail',
        params,
      }),
      transformResponse: response => {
        let results = response.map((result, index) => {
          return {
            id: index,
            ...result,
          }
        })
        return taskDetailAdapter.setAll(initialTaskDetail, results)
      },
      providesTags: ['TaskDetail'],
    }),
  }),
})

export const {
  useGetAddReqInfoQuery,
  useGetApprLinesProcQuery,
  useGetServiceResultQuery,
  useGetTaskDetailQuery,
} = extendedApiSlice
