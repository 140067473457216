import { useState } from 'react'

import Button from '../components/common/TheButton'
import Calendar from '../components/common/TheCalendar'
import CodeList from '../components/common/TheCodeList'
import Input from '../components/common/TheInput'
import ScrollTop from '../components/common/TheScrollTop'
import Dropdown from '../components/common/TheSelect'

export default function SearchRequest() {
  const options = [
    { label: '선택옵션1', value: '선택옵션1' },
    { label: '선택옵션2', value: '선택옵션2' },
    { label: '선택옵션3', value: '선택옵션3' },
    { label: '선택옵션4', value: '선택옵션4' },
  ]
  const [company, setCompany] = useState(options[0].value)
  const [requestType, setRequestType] = useState(options[0].value)
  const [searchType, setSearchType] = useState(options[0].value)
  const [inputValue, setInputValue] = useState('')

  const [isExpand, setIsExpand] = useState(false)
  const [rangeObj, setRangeObj] = useState({})

  const list = [
    {
        code: '단순요청',
        code_number: 'SR05000003590',
        state: '승인',
        content: '담당자를 변경해주세요.',
        classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
        date: '2023-03-20 16:45',
        manager: '김길동 매니저',
      },
      {
        code: '변경요청',
        code_number: 'SR05000003590',
        state: '반려',
        content: '시스템 설치에 관한 문의드립니다..',
        classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
        date: '2023-03-20 16:45',
        manager: '김길동 매니저',
      },
      {
        code: '문제/개선',
        code_number: 'SR05000003590',
        state: '승인',
        content: '문제 해결',
        classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
        date: '2023-03-20 16:45',
        manager: '김길동 매니저',
      },
      {
        code: '장애요청',
        code_number: 'SR05000003590',
        state: '승인',
        content: '문제 해결',
        classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
        date: '2023-03-20 16:45',
        manager: '김길동 매니저',
      },
      {
        code: '릴리스',
        code_number: 'SR05000003590',
        state: '승인',
        content: '릴리즈 요청사항입니다.',
        classification: 'U.STRA ITSM 서비스 담당자 변경 요청',
        date: '2023-03-20 16:45',
        manager: '김길동 매니저',
      },
  ]

  const onClickExpandButton = _ => {
    setIsExpand(!isExpand)
  }

  const onClickSearchButton = _ => {
    console.table([
      ['회사구분', company],
      ['요청유형', requestType],
      ['요청일', rangeObj],
      ['검색어 유형', searchType],
      ['검색어', inputValue],
    ])
  }

  const onChangeRangePicker = value => {
    setRangeObj(value)
  }

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div className='search__card'>
          <Dropdown
            value={company}
            id='select_company'
            label='회사구분'
            options={options}
            onChange={e => setCompany(e.curr.value)}
          />
          {!!isExpand && (
            <Dropdown
              value={requestType}
              id='select_type'
              label='요청유형'
              options={options}
              onChange={e => setRequestType(e.curr.value)}
            />
          )}
          <Calendar
            type='range'
            id='range1'
            datePicker={rangeObj}
            label='요청일'
            format='yyyy-MM-dd'
            onChange={onChangeRangePicker}
          />

          {!!isExpand && (
            <>
              <span className='text-primary fw-600'>검색어</span>
              <div className='d-flex'>
                <Dropdown
                  id='select_search'
                  value={searchType}
                  options={options}
                  onChange={e => setSearchType(e.curr.value)}
                  width='132px'
                />
                <Input
                  className='mt-6 mb-20 ml-4'
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                />
              </div>
            </>
          )}

          <Button
            type='btn'
            onClick={onClickSearchButton}>
            조회
          </Button>
          <Button
            type='shuffle'
            variant='secondary'
            onClick={onClickExpandButton}>
            {isExpand ? '닫기' : '검색조건 더보기'}
            <span className={`arrow__icon ${isExpand ? '' : 'expand'}`}> </span>
          </Button>
        </div>

        <div className='search__list'>
          <div className='search__list-title mb-12'>
            <h2>서비스 요청 목록</h2>
            <span>4건</span>
          </div>

          <CodeList list={list} />

          {list.length === 0 ? null : (
            <Button
              type='shuffle'
              variant='secondary'>
              더보기
              <span className='arrow__icon expand' />
            </Button>
          )}
        </div>
      </div>
      <ScrollTop />
    </main>
  )
}
