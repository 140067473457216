const Radio = ({
  label,
  name,
  defaultChecked,
  disabled,
  isDisabled,
  readOnly,
  isreadOnly,
  onClick,
  value,
}) => {
  return (
    <div className='radioGroup'>
      <label
        className={
          'radio__label' +
          (isDisabled ? ' is-disabled' : '') +
          (isreadOnly ? ' is-readonly' : '')
        }>
        <input
          type='radio'
          value={value}
          className='radio__input'
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
          readOnly={readOnly}
          onClick={onClick}
        />
        <span className='radio__style'></span> {label}
      </label>
    </div>
  )
}
export default Radio
