const Input = ({
  className,
  label,
  type = 'text',
  value,
  placeholder,
  disabled,
  readonly,
  onChange,
  error = false,
  errorText,
  onKeyUp,
  onBlur,
  maxLength,
  autoComplete,
}) => {
  return (
    <div className={`inputView ${className}`}>
      <span className='text-primary fw-600'>{label}</span>
      <div className='inputWrap'>
        {type === 'textarea' ? (
          <textarea
            className='text__textarea'
            value={value}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            onChange={onChange}></textarea>
        ) : (
          <>
            <input
              type={type === 'password' ? 'password' : 'text'}
              className={`text__${type === 'search' ? 'search' : 'input'} 
                ${!!error ? ' is-error' : ''}`}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onBlur={onBlur}
              autoComplete={autoComplete}
              maxLength={maxLength}
            />
            {type === 'search' && (
              <button
                type='button'
                className='btn__search'
                disabled={disabled}>
                검색
              </button>
            )}
            {!!error && !!errorText && (
              <div className='error-text'>{errorText}</div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Input
