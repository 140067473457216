import { decode } from 'html-entities'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../common/TheButton'
import Code from '../common/TheCode'

export default function Content({
  contentId,
  contents,
  buttonType = null,
  onlyDate = false,
  onlyDateManager = false,
}) {
  const [N, setN] = useState(10)

  const loadMore = () => {
    setN(N + 10)
  }

  const renderButton = () => {
    if (contents[contentId].length > N) {
      return (
        <div className='flex justify-center'>
          <Button
            type='shuffle'
            variant='secondary'
            overrideStyle={{
              width: '100%',
              height: '46px',
              marginTop: '14px',
              marginLeft: 0,
              fontSize: '15px',
              color: '#333333',
              border: '1px solid #cccccc',
            }}
            onClick={() => loadMore()}>
            더보기
            <span className='arrow__icon expand' />
          </Button>
        </div>
      )
    }
  }

  // contents[contentId]가 존재하지 않을 때, 또는 빈 배열일 때 NotFound 컴포넌트를 렌더링.
  if (!contents[contentId] || contents[contentId].length === 0) {
    return (
      <div className='no__list'>
        <span> No data. </span>
        <span> 목록이 존재하지 않습니다. </span>
      </div>
    )
  }

  const limitedList = Object.entries(contents[contentId])
    .slice(0, N)
    .map(([ids, entities]) => {
      return {
        authorId: entities.createEmpId,
        code: entities.reqTyNm,
        code_number: entities.srId,
        state: entities.apprStatusCod
          ? entities.apprStatusCod
          : entities.approvalStatus,
        content: entities.srTitlNm,
        classification: '',
        date: entities.reqDt,
        reqManager: entities.qusrNm,
        apprDt: entities.apprDt,
      }
    })

  return (
    <>
      <div className='pb-50 pl-16 pr-16'>
        {limitedList.length === 0 ? (
          <div className='no__list'>작성한 문서가 없습니다.</div>
        ) : (
          limitedList.map((item, index) => (
            <Link
              key={index}
              to={`/approval/${item.authorId}/${item.code_number}`}
              className={!!buttonType ? `is-${buttonType}` : ''}>
              <div className='code__list'>
                {item.code && (
                  <>
                    <Code
                      code={item.code}
                      code_number={item.code_number}
                      state={item.state}
                    />
                    <div className='code__list-content pb-6'>
                      {decode(item.content)}
                    </div>
                  </>
                )}

                {/* 작업 처리내역 */}
                {item.processingNumber && (
                  <>
                    <span className='code__list-processing'>
                      <em>{item.processingNumber}</em>차 처리
                    </span>
                    <div className='code__list-content'>
                      {decode(item.content)}
                    </div>
                  </>
                )}
                {/*  */}

                {!!onlyDate ? (
                  <div className='code__list-bottom'>{item.date}</div>
                ) : !!onlyDateManager ? (
                  <div className='code__list-bottom2'>
                    <span>{item.date}</span>
                    <span>{item.reqManager}</span>
                  </div>
                ) : (
                  <>
                    {!!item.classification && (
                      <div className='code__list-bottom'>
                        <span>요청분류</span>
                        <span>{item.classification}</span>
                      </div>
                    )}

                    <div className='code__list-bottom'>
                      <span>요청일시</span>
                      <span>{item.date}</span>
                    </div>
                    {item.apprDt && (
                      <div className='code__list-bottom'>
                        <span>결재일시</span>
                        <span>{item.apprDt}</span>
                      </div>
                    )}
                    <div className='code__list-bottom'>
                      <span>요청자</span>
                      <span>{item.reqManager}</span>
                    </div>
                  </>
                )}
              </div>
            </Link>
          ))
        )}
        {renderButton()}
      </div>
    </>
  )
}
