import {
  Navigate,
  Outlet,
  useLocation,
  useOutletContext,
} from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

export function PrivateOutlet() {
  const context = useOutletContext()
  const {
    user: { id },
  } = useAuth()
  const location = useLocation()

  return id ? (
    <Outlet context={context} />
  ) : (
    <Navigate
      to='/login'
      state={{ from: location }}
    />
  )
}
