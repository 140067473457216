import { Link, useNavigate, useRouteError } from 'react-router-dom'
import Button from '../../components/common/TheButton'

export default function ErrorPageNoAuth() {
  const error = useRouteError()
  const navigate = useNavigate()
  // console.log(error)

  return (
    <div className='error__page'>
      <h3>접근 권한이 없습니다.</h3>
      <p>관리자에게 문의해주세요.</p>
      <div className='error__pageBtn'>
        <Button
          type='btn'
          variant='secondary'
          overrideStyle={{
            width: '25%',
            height: '42px',
            fontSize: '15px',
            color: '#333333',
            border: '1px solid #cccccc',
          }}
          onClick={() => navigate(-1)}>
          뒤로가기
        </Button>
        <Link
          className='btn is-primary'
          style={{
            width: '25%',
            height: '42px',
            fontSize: '15px',
          }}
          to={'/'}>
          홈으로
        </Link>
      </div>
    </div>
  )
}
