import { apiSlice } from '../../app/services/apiSlice'

export interface reissuePasswordResponse {
  userId: string
  coId: string
}

export interface initPasswordResponse {
  userId: string
  coId: string
  ExistingPasswordInput: string
  confirmPasswordInput: string
  passwordInput: string
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    reissuePass: builder.mutation<
      reissuePasswordResponse,
      Partial<reissuePasswordResponse>
    >({
      query: body => ({
        url: '/help/account/reissuePass',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(body)).toString(),
      }),
      invalidatesTags: ['ReissuePassword'],
    }),
    initPass: builder.mutation<
      initPasswordResponse,
      Partial<initPasswordResponse>
    >({
      query: body => ({
        url: '/help/account/init-passwd',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(body)).toString(),
      }),
      invalidatesTags: ['InitPassword'],
    }),
  }),
})

export const { useReissuePassMutation, useInitPassMutation } = extendedApiSlice
