import Tooltip from './TheTooltip'
import { useMemo, useState } from 'react'
// import '../../assets/scss/components/_the-button.scss'

const Button = ({
  children,
  variant = 'primary',
  type,
  icon,
  tooltipTitle,
  tooltipWidth,
  disabled,
  isChecked,
  value,
  overrideStyle,
  hover,
  block,
  pill,
  squared,
  outline,
  confirm,
  rightIcon,
  onClick,
}) => {
  const [staticClass, setStaticClass] = useState('btn')
  if (type === 'fav') setStaticClass('favBtn')

  const classList = ['primary', 'secondary', 'success', 'warning', 'danger']
  const classObj = {
    [`is-${variant}`]: classList.includes(variant),
    'is-block': block,
    'is-rounded-pill': pill,
    'is-rounded-0': squared && !pill,
    'is-hover': hover,
    'is-outline': outline,
    'is-confirm': confirm,
    'is-reverse': rightIcon,
    'is-search': type === 'search',
    'is-shuffle': type === 'shuffle',
    'is-icon': type === 'sIcon',
    'is-help': type === 'help',
    'is-popupWhite': type === 'popupWhite',
    'is-popupBlack': type === 'popupBlack',
  }
  const statusClass = Object.keys(classObj)
    .filter(key => classObj[key])
    .join(' ')

  let iconClassObj = []
  if (type === 'btn' && icon) {
    iconClassObj.push(`is-${icon}`)
  } else if (type === 'search') {
    iconClassObj.push('is-search')
  } else if (type === 'shuffle') {
    if (!icon) {
      iconClassObj.push('is-arrTop')
    } else {
      iconClassObj.push(`is-${icon}`)
    }
  } else if (type === 'sIcon') {
    iconClassObj.push(`is-${icon}`)
  }

  const iconClass = useMemo(() => {
    return {
      static: 'icon',
      class: iconClassObj.join(' '),
    }
  }, [iconClassObj])

  return (
    <>
      {type === 'help' || type === 'fav' ? (
        <Tooltip
          title={tooltipTitle}
          center-bottom
          un-arrow
          min-width={tooltipWidth}
          disabled={disabled}
          leftIcon
          pointIcon
          centerBottom
          unArrow
          // leftIcon={true}
          // leftBottom={true}
          // pointIcon={true}
        >
          {type === 'help' ? (
            <div className='helpBtn'>
              <button
                className={`${staticClass} ${statusClass}`}
                disabled={disabled}
                onClick={onClick}
                // @click="click"
              />
            </div>
          ) : type === 'fav' ? (
            <label className='favBtn'>
              <input
                className='favBtn__checkbox'
                type='checkbox'
                checked={isChecked}
                disabled={disabled}
                // @change="change"
              />
            </label>
          ) : null}
        </Tooltip>
      ) : (
        <button
          className={`${staticClass} ${statusClass}`}
          value={value}
          disabled={disabled}
          type={type}
          style={overrideStyle}
          onClick={onClick}>
          {icon ||
          type === 'search' ||
          type === 'shuffle' ||
          type === 'sIcon' ? (
            <span className={`${iconClass.static} ${iconClass.class}`}></span>
          ) : null}
          {children}
        </button>
      )}
    </>
  )
}

export default Button
