const Checkbox = ({
  label,
  defaultChecked,
  disabled,
  isDisabled,
  readOnly,
  isreadOnly,
  onChange,
  onClick,
  value,
  checked,
}) => {
  return (
    <div className='checkGroup'>
      <label
        className={
          'check__label' +
          (isDisabled ? ' is-disabled' : '') +
          (isreadOnly ? ' is-readonly' : '')
        }>
        <input
          className='check__input'
          type='checkbox'
          defaultChecked={defaultChecked}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          onClick={onClick}
          value={value}
          checked={checked}
        />
        <span className='check__style'>{label}</span>
      </label>
    </div>
  )
}
export default Checkbox
