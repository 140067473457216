import { Link } from 'react-router-dom'
import RequestContent from '../../components/common/TheRequestContent'
import { useLoaderData, useOutletContext } from 'react-router-dom'
import {
  useGetAddReqInfoQuery,
  useGetApprLinesProcQuery,
  useGetServiceResultQuery,
  useGetTaskDetailQuery,
} from '../requestDetail/requestDetailSlice'
import OrderApproval from '../../components/common/TheOrderApprovalForRequest'
import Card from '../../components/common/TheCard'
import Button from '../../components/common/TheButton'
import { useAuth } from '../../hooks/useAuth'
import { ContentFormNosrCnTmlSe } from '../common/ContentForm'
import ProgressApproval from '../../components/common/TheProgressApproval'
import CodeList from '../../components/common/TheCodeList'
import { useEffect, useState } from 'react'
import { selectDomain } from '../auth/domainSlice'
import { useSelector } from 'react-redux'
import Loader from '../../components/common/TheLoader'

export default function MyListDetails() {
  const [currentMenu, setCurrentMenu] = useOutletContext()
  const paramData = useLoaderData()
  const reqTySe = paramData.reqTySe
  const srId = paramData.srId
  const createEmpId = paramData.createEmpId
  let realReqTySe
  if (reqTySe === '0215') {
    realReqTySe = 'servicesmplresult'
  } else if (reqTySe === '0223') {
    realReqTySe = 'serviceinciresult'
  } else if (reqTySe === '0179') {
    realReqTySe = 'servicechgdevresult'
  } else if (reqTySe === '1045') {
    realReqTySe = 'serviceprobresult'
  } else if (reqTySe === '1046') {
    realReqTySe = 'servicerelsresult'
  }

  const { user } = useAuth()

  // 회사 경로 import
  const domainsPath = useSelector(selectDomain)

  // 도메인 경로 찾기
  let domainAd = null
  if (domainsPath && domainsPath.entities) {
    const entities = domainsPath.entities

    // entities 객체를 순회하며 coId를 체크하여 user.domain과 일치하는 객체의 DomainAd 값을 찾음
    for (const key in entities) {
      if (entities[key].coId === user.domain) {
        domainAd = entities[key].domainAd
        break
      }
    }
  }

  const {
    data: serviceResult = [],
    isLoading: isserviceResultLoading,
    isFetching: isServiceResultFetching,
    isSuccess: isServiceResultSuccess,
    isError: isServiceResultError,
    error: serviceResultError,
  } = useGetServiceResultQuery({
    srId,
    reqTyCd: realReqTySe,
  })
  const {
    data: taskDetail = [],
    isLoading: isTaskDetailLoading,
    isFetching: isTaskDetailFetching,
    isSuccess: isTaskDetailSuccess,
    isError: isTaskDetailError,
    error: taskDetailError,
  } = useGetTaskDetailQuery({
    srId,
  })
  let timelinelist = serviceResult?.timelinelist ?? ''
  let baseinfo = serviceResult?.baseinfo ?? ''
  let filelist = serviceResult?.filelist ?? ''
  let smplresultinfo = serviceResult?.smplresultinfo ?? ''
  let inciresultinfo = serviceResult?.inciresultinfo ?? ''
  let probresultinfo = serviceResult?.probresultinfo ?? ''
  let chgdevresultinfo = serviceResult?.resultinfo ?? ''
  let relsplaninfo = serviceResult?.relsplaninfo ?? ''
  let workinfo = serviceResult?.workinfo ?? ''
  let workinfosummary = serviceResult?.workinfosummary ?? ''

  const { data: serviceResultBuf } = useGetServiceResultQuery({
    srId,
    reqTyCd: realReqTySe,
    timelinelist: timelinelist,
    baseinfo: baseinfo,
    filelist: filelist,
    smplresultinfo: smplresultinfo,
    inciresultinfo: inciresultinfo,
    probresultinfo: probresultinfo,
    chgdevresultinfo: chgdevresultinfo,
    relsplaninfo: relsplaninfo,
    workinfo: workinfo,
    workinfosummary: workinfosummary,
  })
  /* 요청내용 - 추가요청(공통) S */
  const {
    data: addReqInfo = [],
    isLoading: isAddReqInfoLoading,
    isFetching: isAddReqInfoFetching,
    isSuccess: isAddReqInfoSuccess,
    isError: isAddReqInfoError,
    error: addReqInfoError,
  } = useGetAddReqInfoQuery({
    perPage: '',
    srId,
    page: 1,
  })
  /* 요청내용 - 추가요청(공통)  E */

  const { data: apprLinesProc = [] } = useGetApprLinesProcQuery({
    srId,
    createCoId: user.domain,
    createEmpId: user.id,
  })

  const newOrderData = apprLinesProc.entities
    ? Object.entries(apprLinesProc.entities).map(([ids, entities]) => ({
        name: entities.apprEmpNm + ' ' + entities.apprDutyNm,
        date: entities.apprDt,
        status: [entities.srOrd, entities.apprTy], // status 값을 배열로 변경
      }))
    : []

  // 진행상태
  const newProgressData = Array.isArray(timelinelist)
    ? timelinelist.map((item, index) => ({
        progress: index === timelinelist.length - 1 ? 'do' : 'did',
        status: item.actiNm,
      }))
    : []

  const currentProgressData =
    timelinelist[timelinelist?.length - 1]?.actiNm ?? ''

  let ApprovalItem = ''
  if (!!baseinfo) {
    ApprovalItem = {
      ...baseinfo,
      code: baseinfo.reqTyNm,
      code_number: baseinfo.srId,
      title: baseinfo.srTitlNm,
      content: baseinfo.srCn,
      srCnTmlSe: baseinfo.srCnTmlSe,
      classification: baseinfo.reqClNm,
      clientClassification: baseinfo.cltReqNm,
      client: baseinfo.srCcompNm,
      reqManager:
        baseinfo.qusrNm +
        ' ' +
        (baseinfo.qusrPosiNm ? baseinfo.qusrPosiNm : ''),
      /* ----- */
      requestService: baseinfo.servPathNm,
      requestDate: baseinfo.reqDt,
      receptionist: baseinfo.eusrNm,
      completeHopeDate: baseinfo.chgCmplHopeDtStr,
      finalDate: baseinfo.chgCmplAgreDtStr,
      completeDate: baseinfo.cmplDt,
      manager: baseinfo.hdlNm, // 처리자
      managerPosition: baseinfo.hdlPosiNm,
      addContent: baseinfo.secuCn,
      domainAd: domainAd,
    }
  }
  // 변경요청 - 사전검토
  const preliminaryReviewItem = [
    {
      title: '긴급여부',
      content: null,
    },
    {
      title: '난이도',
      content: null,
    },
    {
      title: '사전분석결과',
      content: null,
    },
  ]

  // 변경요청 - CAB내용
  const cabContentItem = [
    {
      title: 'CAB소집일시',
      content: null,
    },
    {
      title: 'CAB참여자',
      content: null,
    },
    {
      title: 'CAB검토결과',
      content: null,
    },
    {
      title: '타시스템 영향 평가결과',
      content: null,
    },
  ]

  // 문제/개선 - 해결방안
  const solutionPlanItem = [
    {
      title: '문제등급',
      content: null,
    },
    {
      title: '근본원인',
      content: null,
    },
    {
      title: '해결방안',
      content: null,
    },
    {
      title: '예상공수',
      content: null,
    },
  ]

  // 릴리스 - 릴리스계획
  const relsPlanItem = [
    {
      title: '긴급여부',
      content: null,
    },
    {
      title: '업무중단예정여부',
      content: null,
    },
    {
      title: '업무중단일시',
      content: null,
    },
    {
      title: '작업예정일시',
      content: null,
    },
    {
      title: '작업예정내용',
      content: null,
    },
    {
      title: '원상복구계획',
      content: null,
    },
  ]

  // 변경요청 - 처리내용 표식화
  const processingItem = [
    {
      title: '작업내용',
      content: null,
    },
  ]

  // 단순요청 - 처리내용
  const smplprocessingItem = [
    {
      title: '작업내용',
      content: null,
    },
  ]

  // 장애요청 - 처리내용
  const inciprocessingItem = [
    {
      title: '장애발생일시',
      content: null,
    },
    {
      title: '장애등급',
      content: null,
    },
    {
      title: '장애유형',
      content: null,
    },
    {
      title: '해결유형',
      content: null,
    },
    {
      title: '처리구분',
      content: null,
    },
    {
      title: '시스템복구일시',
      content: null,
    },
    {
      title: '업무복구일시',
      content: null,
    },
    {
      title: '작업내용',
      content: null,
    },
  ]

  // 문제/개선 - 처리내용
  const probprocessingItem = [
    {
      title: '장애유형',
      content: null,
    },
    {
      title: '해결유형',
      content: null,
    },
    {
      title: '해결내용',
      content: null,
    },
    {
      title: '작업내용',
      content: null,
    },
  ]

  // 릴리스 - 처리내용
  const relsprocessingItem = [
    {
      title: '작업일시',
      content: null,
    },
    {
      title: '형상등록검토내용',
      content: null,
    },
    {
      title: '배포처리내용',
      content: null,
    },
    {
      title: '테스트처리내용',
      content: null,
    },
    {
      title: '작업수행결과',
      content: null,
    },
  ]

  const clientReqResponseItem = [
    {
      title: '고객요청 답변내용',
      content: null,
    },
  ]

  const customerSatisfactionItem = [
    {
      title: '고객만족도',
      content: null,
    },
    {
      title: '만족도의견',
      content: null,
    },
  ]

  const returnOpinion = [
    {
      title: '반려의견',
      content: null,
    },
  ]

  const reqTypeMap = {
    단순요청: 'simple',
    장애요청: 'error',
    변경요청: 'change',
    '문제/개선': 'problem',
    릴리스: 'deployment',
  }

  const addReqProcessingList = addReqInfo.entities
    ? Object.entries(addReqInfo.entities).map(([ids, entities]) => ({
        addReqprocessingNumber: entities?.cmmCodNm,
        addReqType: reqTypeMap[entities?.cmmCodNm],
        addReqcontent: entities?.srTitlNm,
        addReqdate: entities?.reqDt,
        addReqmanager: entities?.qusrNm,
        addReqreqSe: entities?.reqSe,
      }))
    : []

  let AddProcessingContent = ''
  AddProcessingContent = (
    <main className='content'>
      <div className='pb-50 pl-16 pr-16'>
        <CodeList
          list={addReqProcessingList}
          onlyAddReq
        />
      </div>
    </main>
  )

  let taskDetailCode = taskDetail.entities
    ? Object.entries(taskDetail.entities).map(([ids, entities]) => ({
        ...entities,
        manager: entities.husrNm,
        step: entities.stepSeNm,
        content: (
          <>
            <ContentFormNosrCnTmlSe srCn={entities.hdlCn} />
          </>
        ),
      }))
    : []

  const ChangeProcessingItem = [
    {
      title: '작업공정유형',
      content: workinfosummary.procsTyNm,
    },
    {
      title: '작업내용',
      headerList: [
        { name: '단계', value: 'step', width: '70px' },
        { name: '처리자', value: 'manager', width: '70px' },
        { name: '처리내역', value: 'content' },
      ],
      list: taskDetailCode,
    },
    {
      title: '릴리스 요청',
      content: workinfosummary.rlsAt === 'N' ? '아니오' : '예',
    },
    ,
    { title: '변경결과 구분', content: workinfosummary?.chgRslSeNm },
  ]

  if (realReqTySe === 'servicesmplresult') {
    // 단순요청
    if (smplresultinfo.smplReqHdlCn) {
      smplprocessingItem[0].content = (
        <ContentFormNosrCnTmlSe srCn={smplresultinfo.smplReqHdlCn} />
      )
    }
    if (smplresultinfo.ntcCn) {
      clientReqResponseItem[0].content = smplresultinfo.ntcCn
    }
    if (smplresultinfo.stftRtNm) {
      customerSatisfactionItem[0].content = smplresultinfo.stftRtNm
    }
    if (smplresultinfo.stftRtOpnnCn) {
      customerSatisfactionItem[1].content = smplresultinfo.stftRtOpnnCn
    } else {
      customerSatisfactionItem[1].content = 'No data'
    }
    if (smplresultinfo.rclCn) {
      returnOpinion[0].content = smplresultinfo.rclCn
    }
  } else if (realReqTySe === 'serviceinciresult') {
    // 장애요청
    if (workinfo.icdtOcrDt) {
      inciprocessingItem[0].content = workinfo.icdtOcrDt
      inciprocessingItem[1].content = (
        <>
          {workinfo.icdtCsNm}(긴급도: {workinfo.icdtEmerRtNm}/ 영향도:{' '}
          {workinfo.icdtInflRtNm})
        </>
      )
      inciprocessingItem[2].content = (
        <>
          {workinfo.icdtRsnClNm} {'>'} {workinfo.icdtRsnSeNm}
        </>
      )
      inciprocessingItem[3].content = workinfo.icdtSolNm
      inciprocessingItem[4].content = workinfo.icdtHdlMethNm
      inciprocessingItem[5].content = workinfo.sysRstrDt
      inciprocessingItem[6].content = workinfo.servRstrDt
    }

    if (inciresultinfo.icdtHdlCn) {
      inciprocessingItem[7].content = (
        <ContentFormNosrCnTmlSe srCn={inciresultinfo.icdtHdlCn} />
      )
    }
    if (inciresultinfo.ntcCn) {
      clientReqResponseItem[0].content = inciresultinfo.ntcCn
    }
    if (inciresultinfo.stftRtNm) {
      customerSatisfactionItem[0].content = inciresultinfo.stftRtNm
    }
    if (inciresultinfo.stftRtOpnnCn) {
      customerSatisfactionItem[1].content = inciresultinfo.stftRtOpnnCn
    } else {
      customerSatisfactionItem[1].content = 'No data'
    }
    if (inciresultinfo.rclCn) {
      returnOpinion[0].content = inciresultinfo.rclCn
    }
  } else if (realReqTySe === 'servicechgdevresult') {
    // 변경요청
    if (workinfosummary.emerRtNm) {
      preliminaryReviewItem[0].content = workinfosummary.emerRtNm
    }
    if (workinfosummary.difRtNm) {
      preliminaryReviewItem[1].content = workinfosummary.difRtNm
    }
    if (workinfosummary.bfrRevRslCn) {
      preliminaryReviewItem[2].content = (
        <ContentFormNosrCnTmlSe srCn={workinfosummary.bfrRevRslCn} />
      )
    }

    if (workinfosummary.cabCallDt) {
      cabContentItem[0].content = workinfosummary.cabCallDtStr
    }
    if (workinfosummary.cabCallAt) {
      cabContentItem[1].content = workinfosummary.cabCallAt
    }

    if (workinfosummary.cabRevRslCn) {
      cabContentItem[2].content = (
        <ContentFormNosrCnTmlSe srCn={workinfosummary.cabRevRslCn} />
      )
    } else {
      cabContentItem[2].content = 'No data'
    }

    if (workinfosummary.cabOthSysInflAsmtCn) {
      cabContentItem[3].content =
        workinfosummary.cabOthSysInflAsmtCn ?? 'No data.'
    } else {
      cabContentItem[3].content = 'No data'
    }

    processingItem[0].content = ChangeProcessingItem[0]?.content
    // 변경요청만 했을 경우에 고객요청 답변내용 파라미터 변수명이 ntcCn 이지만,
    // 변경요청 -> 릴리스 로 넘어간 고객요청 답변내용 파라미터 변수명이 wrkCn 입니다.
    if (chgdevresultinfo.ntcCn) {
      clientReqResponseItem[0].content = chgdevresultinfo.ntcCn
    } else if (!chgdevresultinfo.ntcCn && chgdevresultinfo.wrkCn) {
      clientReqResponseItem[0].content = chgdevresultinfo.wrkCn
    }
    if (chgdevresultinfo.stftRtNm) {
      customerSatisfactionItem[0].content = chgdevresultinfo.stftRtNm
    }
    if (chgdevresultinfo.stftRtOpnnCn) {
      customerSatisfactionItem[1].content = chgdevresultinfo.stftRtOpnnCn
    } else {
      customerSatisfactionItem[1].content = 'No data'
    }
    if (chgdevresultinfo.rclCn) {
      returnOpinion[0].content = chgdevresultinfo.rclCn
    }
  } else if (realReqTySe === 'serviceprobresult') {
    // 문제/개선
    if (workinfo.probCsNm) {
      solutionPlanItem[0].content = workinfo.probCsNm
    }
    if (workinfo.probBssRsnCn) {
      solutionPlanItem[1].content = workinfo.probBssRsnCn
    }
    if (workinfo.probTempSolCn) {
      solutionPlanItem[2].content = (
        <ContentFormNosrCnTmlSe srCn={workinfo.probTempSolCn} />
      )
    }
    if (workinfo.exptCstAm) {
      solutionPlanItem[3].content = `${workinfo.exptCstAm.toFixed(1)} M/H`
    }

    if (workinfo.probWrkCn) {
      probprocessingItem[0].content = (
        <>
          {workinfo.probRsnClNm} {'>'} {workinfo.probRsnSeNm}
        </>
      )
      probprocessingItem[1].content = workinfo.probSolSeNm
      probprocessingItem[2].content = workinfo.probSolAltCn
      probprocessingItem[3].content = (
        <ContentFormNosrCnTmlSe srCn={workinfo.probWrkCn} />
      )
    }
    if (probresultinfo.rclCn) {
      returnOpinion[0].content = probresultinfo.rclCn
    }
  } else if (realReqTySe === 'servicerelsresult') {
    // 릴리스
    if (relsplaninfo.emerRtNm) {
      relsPlanItem[0].content = relsplaninfo.emerRtNm
    }

    if (relsplaninfo.planServSuspAt) {
      const data = relsplaninfo.planServSuspAt === 'N' ? '아니오' : '예'
      relsPlanItem[1].content = data
    }

    if (relsplaninfo.planServSuspStrtDt) {
      relsPlanItem[2].content = (
        <>
          {relsplaninfo.planServSuspStrtDt} {'~'}{' '}
          {relsplaninfo.planServSuspCmplDt}
        </>
      )
    }

    if (relsplaninfo.planRlsWrkStrtDt) {
      relsPlanItem[3].content = (
        <>
          {relsplaninfo.planRlsWrkStrtDt} {'~'} {relsplaninfo.planRlsWrkCmplDt}
        </>
      )
    }

    if (relsplaninfo.planRlsWrkCn) {
      relsPlanItem[4].content = (
        <ContentFormNosrCnTmlSe srCn={relsplaninfo.planRlsWrkCn} />
      )
    }

    if (relsplaninfo.rstrPlanCn) {
      relsPlanItem[5].content = relsplaninfo.rstrPlanCn
    }
    if (relsplaninfo?.rlsWrkStrtDt) {
      relsprocessingItem[0].content = (
        <>
          {relsplaninfo.rlsWrkStrtDt} {'~'} {relsplaninfo.rlsWrkCmplDt}
        </>
      )
    }
    if (relsplaninfo.cfgRegsRvwCn) {
      relsprocessingItem[1].content = (
        <ContentFormNosrCnTmlSe srCn={relsplaninfo.cfgRegsRvwCn} />
      )
    }

    if (relsplaninfo.dstrProcCn) {
      relsprocessingItem[2].content = (
        <ContentFormNosrCnTmlSe srCn={relsplaninfo.dstrProcCn} />
      )
    } else {
      relsprocessingItem[2].content = 'No data'
    }

    if (relsplaninfo.tstProcCn) {
      relsprocessingItem[3].content = (
        <ContentFormNosrCnTmlSe srCn={relsplaninfo.tstProcCn} />
      )
    } else {
      relsprocessingItem[3].content = 'No data'
    }

    if (relsplaninfo.rlsWrkRslCn) {
      relsprocessingItem[4].content = (
        <ContentFormNosrCnTmlSe srCn={relsplaninfo.rlsWrkRslCn} />
      )
    } else {
      relsprocessingItem[4].content = 'No data'
    }
  }
  {
    /* 결재건 E */
  }

  const [isDetailLoading, setIsDetailLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      // Loader 제거
      setIsDetailLoading(false)
    }, 3000)
  }, [])

  let commonContent = (
    <>
      {/* 변경요청 S */}
      {preliminaryReviewItem[0].content && (
        <Card
          title='사전검토'
          items={preliminaryReviewItem}
        />
      )}

      {cabContentItem[0].content && (
        <Card
          title='CAB내용'
          items={cabContentItem}
        />
      )}
      {/* 변경요청 E */}

      {/* 문제/개선 S */}
      {solutionPlanItem[0].content && (
        <Card
          title='해결방안'
          items={solutionPlanItem}
        />
      )}
      {/* 문제/개선 E */}

      {/* 릴리스 S */}
      {relsPlanItem[0].content && (
        <Card
          title='릴리스계획'
          items={relsPlanItem}
        />
      )}
      {/* 릴리스 E */}

      {/* 단순요청 처리내용 - 작업내용 S */}
      {smplprocessingItem[0].content && (
        <Card
          title='처리내용'
          items={smplprocessingItem}>
          <Link to={`/work-history/${srId}`}>
            <Button
              type='btn'
              variant='secondary'>
              작업 처리내역
            </Button>
          </Link>
        </Card>
      )}
      {/* 단순요청 처리내용 - 작업내용 E */}

      {/* 장애요청 처리내용 - 작업내용 S */}
      {inciprocessingItem[0].content && (
        <Card
          title='처리내용'
          items={inciprocessingItem}></Card>
      )}
      {/* 장애요청 처리내용 - 작업내용 E */}

      {/* 변경요청 처리내용 - 작업내용 S */}
      {taskDetail.entities && taskDetail.entities[0]?.husrNm && (
        <Card
          title='처리내용'
          items={ChangeProcessingItem}></Card>
      )}
      {/* 변경요청 처리내용 - 작업내용 E */}

      {/* 문제/개선 처리내용 - 작업내용 S */}
      {probprocessingItem[0].content && (
        <Card
          title='처리내용'
          items={probprocessingItem}></Card>
      )}
      {/* 문제/개선 처리내용 - 작업내용 E */}

      {/* 릴리스 처리내용 - 작업내용 S */}
      {relsprocessingItem[0].content && (
        <Card
          title='처리내용'
          items={relsprocessingItem}></Card>
      )}
      {/* 릴리스 처리내용 - 작업내용 E */}

      {clientReqResponseItem[0].content && (
        <Card
          title='결과통보'
          items={clientReqResponseItem}
        />
      )}

      {customerSatisfactionItem[0].content && (
        <Card
          title='고객만족도'
          items={customerSatisfactionItem}
        />
      )}
      {returnOpinion[0].content && (
        <Card
          title='반려의견'
          items={returnOpinion}
        />
      )}
      {addReqInfo.entities && addReqInfo.entities[0]?.cmmCodNm && (
        <Card title='추가요청'>{AddProcessingContent}</Card>
      )}
    </>
  )
  let information = ''

  if (isserviceResultLoading || isTaskDetailLoading || isAddReqInfoLoading) {
    //Spinner
    information = <Loader className='loading' />
  } else if (
    isServiceResultSuccess ||
    isTaskDetailSuccess ||
    isAddReqInfoSuccess
  ) {
    information = (
      <>
        <div className='bg-blue-100 pb-8 pt-60'></div>
        <div className='bg-blue-100 pb-8'>
          <ProgressApproval progressData={newProgressData} />
        </div>
        {apprLinesProc.entities && apprLinesProc.entities[0]?.apprEmpNm && (
          <div className='bg-blue-100 pb-8'>
            <OrderApproval orderData={newOrderData} />
          </div>
        )}
        <div className='mt-30 bg-blue-100'>
          <RequestContent
            item={ApprovalItem}
            filelist={filelist}
          />

          <div className='pb-24 pl-16 pr-16 pt-24'>{commonContent}</div>
        </div>
      </>
    )
  } else if (isServiceResultError || isTaskDetailError || isAddReqInfoError) {
    information = (
      <p>
        {(serviceResultError || taskDetailError || addReqInfoError).toString()}
      </p>
    )
  }

  useEffect(() => {
    setCurrentMenu(`${baseinfo?.reqTyNm ?? ''} - ${currentProgressData}`)
  }, [setCurrentMenu, baseinfo?.reqTyNm])

  return <main className='content'>{information}</main>
}
