import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useLogoutMutation, util } from '../features/auth/authSlice'
import { useAuth } from '../hooks/useAuth'
import SidebarMenu from './SidebarMenu'

export default function Sidebar({ menuOpen, handleMenuOpen, navigation }) {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const [logout] = useLogoutMutation()

  const handleClick = async event => {
    event.preventDefault()

    try {
      await logout().unwrap()
      await dispatch(util.resetApiState())
      // Being that the result is handled in extraReducers in authSlice,
      // we know that we're authenticated after this, so the user
      // and token will be present in the store
      handleMenuOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      className={menuOpen ? 'gnb open' : 'gnb'}
      id='gnb'
      data-modal='modal'>
      <div
        className='gnb__mask'
        data-modal-close='close'
        onClick={() => handleMenuOpen(false)}></div>
      <div className='gnb__inner'>
      <button
          className='gnb__close'
          data-modal-close='close'
          onClick={() => handleMenuOpen(false)}>
          닫기
        </button>
        <div className='gnbUser'>
          <Link
            className='gnbHome'
            to='/'
            onClick={() => handleMenuOpen(false)}>
            Home
          </Link>
          <span className='gnbUser__name'>
            <em>{user.name}</em>님
          </span>
          <Link
            to='/login'
            className='gnb__loginout'
            onClick={handleClick}>
            로그아웃
          </Link>
        </div>
        <div className='gnbMenu'>
          <SidebarMenu
            menuOpen={menuOpen}
            handleMenuOpen={handleMenuOpen}
            navigation={navigation}
          />
        </div>
      </div>
    </div>
  )
}
