const Loader = ({ className, size }) => {
  return (
    <div className={`loader ${className?? ''} ${size === 'sm' ? 'is-sm' : ''}`}>
      <div className="spinner">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

export default Loader
