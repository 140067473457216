import { NavLink } from 'react-router-dom'
import Content from './Content'

export default function Tabs({ tabId, tabs, contents }) {
  const tabDisplay = title => {
    if (title === '작성한문서') {
      if (contents.docsWritten.length > 0) {
        return (
          <span>
            {title} ({contents.docsWritten.length})
          </span>
        )
      } else if (contents.docsWritten.length >= 99) {
        return <span>{title} (99+)</span>
      } else {
        return <span>{title}</span>
      }
    } else if (title === '결재할문서') {
      if (contents.docsForApproval.length > 0) {
        return (
          <span>
            {title} ({contents.docsForApproval.length})
          </span>
        )
      } else if (contents.docsForApproval.length >= 99) {
        return <span>{title} (99+)</span>
      } else {
        return <span>{title}</span>
      }
    } else if (title === '결재한문서') {
      if (contents.docsApproved.length > 0) {
        return (
          <span>
            {title} ({contents.docsApproved.length})
          </span>
        )
      } else if (contents.docsApproved.length >= 99) {
        return <span>{title} (99+)</span>
      } else {
        return <span>{title}</span>
      }
    }
  }

  return (
    <>
      <div className='pt-10'>
        <div className='tabs'>
          <ul className='tab__list'>
            {tabs.map(tab => (
              <li
                key={tab.name}
                className='tab__item'>
                <NavLink
                  to={`/${tab.to}`}
                  preventScrollReset={true}
                  className={({ isActive }) =>
                    `tab__link ${isActive ? 'is-active' : ''}`.trim()
                  }
                  aria-current={({ isActive }) =>
                    isActive ? 'page' : undefined
                  }>
                  {tabDisplay(tab.name)}
                  &nbsp;
                  {/* {tab.to === 'docs-for-approval' &&
                    contents.docsForApproval.length > 0 &&
                    `(${contents.docsForApproval.length})`}
                  {tab.to === 'docs-written' &&
                    contents.docsWritten.length > 0 &&
                    `(${contents.docsWritten.length})`}
                  {tab.to === 'docs-approved' &&
                    contents.docsApproved.length > 0 &&
                    `(${contents.docsApproved.length})`} */}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Content
        contentId={tabId}
        contents={contents}
      />
    </>
  )
}
