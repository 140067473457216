import { decode } from 'html-entities'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ContentForm,
  ContentFormNullsrCnTmlSe,
} from '../../features/common/ContentForm'
import Button from './TheButton'
import Code from './TheCode'

const RequestContent = ({ item, filelist }) => {
  const { pathname } = useLocation()
  const [isRequestPage, setIsRequestPage] = useState(false)
  const [isStatPage, setIsStatPage] = useState(false)
  const [isMyListPage, setIsMyListPage] = useState(false)

  useEffect(() => {
    setIsRequestPage(checkPage('search-request'))
    setIsStatPage(checkPage('stats-request-totlist-detail'))
    setIsMyListPage(checkPage('my-list-detail'))
  })

  const checkPage = name => {
    return pathname.includes(name)
  }

  return (
    <div className='request__card'>
      <Code
        code={item.code}
        code_number={item.code_number}
        state={item.state}
      />
      <div className='request__card-title'>{decode(item.title)}</div>
      <div className='request__card-content'>
        {item.srCnTmlSe ? (
          <ContentForm
            srCnTmlSe={item.srCnTmlSe}
            srCn={item.srCn}
          />
        ) : (
          <ContentFormNullsrCnTmlSe srCn={item.srCn} />
        )}
      </div>
      <hr />
      {(isStatPage || isMyListPage || isRequestPage) && (
        <>
          {!!item.classification && (
            <div className='request__card-item'>
              <span>요청분류</span>
              <span>{item.classification}</span>
            </div>
          )}

          {!!item.clientClassification && (
            <div className='request__card-item'>
              <span>고객요청분류</span>
              <span>{item.clientClassification}</span>
            </div>
          )}

          {!!item.client && (
            <div className='request__card-item'>
              <span>고객사</span>
              <span>{item.client}</span>
            </div>
          )}
          {!!item.reqManager && (
            <div className='request__card-item'>
              <span>요청자</span>
              <span>{item.reqManager}</span>
            </div>
          )}
        </>
      )}
      {!!item.requestService && (
        <div className='request__card-item'>
          <span>요청서비스</span>
          <span>{item?.requestService?.replaceAll('&gt;', '>')}</span>
        </div>
      )}
      {/* 요청일시 */}
      {!!item.requestDate && (
        <div className='request__card-item'>
          <span>요청일시</span>
          <span>{item.requestDate.slice(0, 16)}</span>
        </div>
      )}
      {/* 접수자 */}
      {!!item.receptionist && (
        <div className='request__card-item'>
          <span>접수자</span>
          <span>
            {item.receptionist} {item.eusrPosiNm}
          </span>
        </div>
      )}
      {!!item.completeHopeDate && (
        <div className='request__card-item'>
          <span>완료희망일시</span>
          <span>{item.completeHopeDate}</span>
        </div>
      )}
      {/* 최종합의일시 */}
      {!!item.finalDate && (
        <div className='request__card-item'>
          <span>최종합의일시</span>
          <span>{item.finalDate}</span>
        </div>
      )}
      {/* 처리상태 */}
      {!!item.hdlStsNm && (
        <div className='request__card-item'>
          <span>처리상태</span>
          <span>{item.hdlStsNm}</span>
        </div>
      )}
      {/* 완료일시 */}
      {!!item.completeDate && (
        <div className='request__card-item'>
          <span>완료일시</span>
          <span>{item.completeDate}</span>
        </div>
      )}
      {/* 처리자 */}
      {!!item.manager && (
        <div className='request__card-item'>
          <span>처리자</span>
          <span>
            {item.manager} {item.managerPosition}
          </span>
        </div>
      )}
      {/* 보안내용 */}
      {!!item.addContent && (
        <div className='request__card-item whitespace-pre-wrap'>
          <span>보안내용</span>
          <span className='whitespace-pre-wrap'>{decode(item.addContent)}</span>
        </div>
      )}
      {/* 첨부파일 */}
      {/* {!!item.attatchFile && (
        <div className='request__card-item'>
          <span>첨부파일</span>
          <span>{item.attatchFile}</span>
          <Button variant='secondary'>다운로드</Button>
        </div>
      )} */}
      {!!filelist &&
        filelist.map((file, index) => (
          <div
            className='request__card-item attatch__file'
            key={file.streFileNm}>
            {index === 0 ? <span>첨부파일</span> : <span></span>}
            <div className='attatch__file-content'>
              <span>{file.orignlFileNm}</span>
              <Button
                variant='secondary'
                onClick={() => {
                  let path = `https://${item.domainAd}/files/${file.atchFileId}/${file.fileSn}/download`
                  window.location.href = path
                }}>
                다운로드
              </Button>
            </div>
          </div>
        ))}
    </div>
  )
}

export default RequestContent
