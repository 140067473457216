const Tooltip = ({
  children,
  icon,
  title,
  disabled,
  tooltipStyle,
  leftIcon,
  pointIcon,
  rightBottom,
  leftBottom,
  centerBottom,
  unArrow,
}) => {
  const tooltipItemObj = {
    'is-rightBottom':
      rightBottom ||
      (rightBottom === false && leftBottom === false && centerBottom === false),
    'is-leftBottom': leftBottom,
    'is-centerBottom': centerBottom,
    'is-unArrow': unArrow,
  }
  const statusClass = Object.keys(tooltipItemObj)
    .filter(key => tooltipItemObj[key])
    .join(' ')

  return (
    <div className={leftIcon ? 'tooltip is-reverse' : 'tooltip'}>
      {children}
      <em
        className={pointIcon ? 'tooltip__item is-point' : 'tooltip__item'}></em>

      {!icon && !disabled ? (
        <span className={`tooltip__content ${statusClass}`}>{title}</span>
      ) : null}

      {icon ? (
        <em className={pointIcon ? 'tooltip__item is-point' : 'tooltip__item'}>
          {!disabled ? (
            <span
              className={`tooltip__content ${statusClass}`}
              style={tooltipStyle}>
              {title}
            </span>
          ) : null}
        </em>
      ) : null}
    </div>
  )
}

export default Tooltip
