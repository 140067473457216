import { useCallback, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Buffer } from 'buffer'

import Input from '../../components/common/TheInput'
import Button from '../../components/common/TheButton'
import Alert from '../../components/common/TheAlert'
import { useInitPassMutation } from '../accountLockPage/helpaccountSlice'
import ErrorPage from '../errorPage/ErrorPage'
import Loader from '../../components/common/TheLoader'

export default function InitPassword() {
  const location = useLocation()

  const [initPassword, { isLoading: isInitLoading, isSuccess: isInitSuccess }] =
    useInitPassMutation()

  const navigate = useNavigate()

  const [currentPwd, setCurrentPwd] = useState('')
  const [changePwd, setChangePwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')

  const preventInput = event => {
    const regExp = /[^0-9a-zA-Z`~!@#$%^&*()\-_=+\\|[\]{};:'",.<>/?]/g
    const enteredInput = event.target
    if (regExp.test(enteredInput.value)) {
      enteredInput.value = enteredInput.value.replace(regExp, '')
    }
  }

  const keyupHandler = e => {
    preventInput(e)
  }

  const blurHandler = e => {
    preventInput(e)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (
      currentPwd.trim() === '' ||
      changePwd.trim() === '' ||
      confirmPwd.trim() === ''
    ) {
      setAlertMsg('필수 값을 입력하세요.')
      onChangeAlertState(false)
      onErrorAlertState(true)
      return
    }

    const formState = {
      userId: Buffer.from(location.state.userId).toString('base64'),
      coId: location.state.coId,
      ExistingPasswordInput: Buffer.from(currentPwd).toString('base64'),
      passwordInput: Buffer.from(changePwd).toString('base64'),
      confirmPasswordInput: Buffer.from(confirmPwd).toString('base64'),
    }

    try {
      await initPassword(formState).unwrap()
      setAlertMsg('비밀번호가 변경되었습니다. 로그인페이지로 이동합니다.')
      onErrorAlertState(false)
      onChangeAlertState(true)
    } catch (err) {
      let errormsg = err.data?.message
      setAlertMsg(errormsg)
      onChangeAlertState(false)
      onErrorAlertState(true)
    }
  }

  const onChangeAlertState = useCallback(value => {
    setIsShowAlert(value)
  }, [])

  const onErrorAlertState = useCallback(value => {
    setIsShowErrorAlert(value)
  }, [])

  if (!location?.state?.userId || !location?.state?.coId) {
    return <ErrorPage />
  }

  return (
    <main className='content login'>
      <div className='loginBox'>
        <i className='logo'>USTRA ITSM</i>
        <h1 className='page__title'>초기 패스워드 설정</h1>
        <Input
          type='password'
          className='mb-10 mt-20'
          value={currentPwd}
          maxLength={17}
          onKeyUp={keyupHandler}
          onBlur={blurHandler}
          required
          onChange={e => setCurrentPwd(e.target.value.replace(' ', ''))}
          placeholder='현재 사용 중인 비밀번호를 입력하세요.'
        />

        {isInitLoading && <Loader className='loading' />}

        <Input
          type='password'
          className='mb-10 mt-10'
          value={changePwd}
          maxLength={17}
          onKeyUp={keyupHandler}
          onBlur={blurHandler}
          onChange={e => setChangePwd(e.target.value.replace(' ', ''))}
          placeholder='변경할 비밀번호를 입력하세요.'
        />
        <Input
          type='password'
          className='mb-10 mt-10'
          value={confirmPwd}
          maxLength={17}
          onKeyUp={keyupHandler}
          onBlur={blurHandler}
          onChange={e => setConfirmPwd(e.target.value.replace(' ', ''))}
          placeholder='변경할 비밀번호를 한번 더 입력하세요.'
        />

        <div className='pwd__rule'>
          비밀번호 규칙 : 영문 대소문자/숫자/특수문자 포함 6~17자
        </div>

        <Button onClick={handleSubmit}>비밀번호 변경</Button>
      </div>
      <footer className='footer'>
        <p className='footer__copyright'>
          COPYRIGHT © 2023 GS ITM. ALL RIGHTS RESERVED.
        </p>
      </footer>

      <Alert
        isShow={isShowAlert}
        type='confirm'
        content={`${alertMsg}`}
        hideClose
        onClose={() => navigate('/login')}
        onConfirm={() => navigate('/login')}
      />
      <Alert
        isShow={isShowErrorAlert}
        type='error'
        content={`${alertMsg}`}
        hideClose
        onConfirm={() => onErrorAlertState(false)}
        onClose={() => onErrorAlertState(false)}
      />
    </main>
  )
}
