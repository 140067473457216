import { useEffect } from 'react'
import DatePicker from 'tui-date-picker'
import { sub } from 'date-fns'
import 'tui-date-picker/dist/tui-date-picker.min.css'

const OneCalendar = ({
  id,
  label,
  placeholder,
  language = 'ko',
  disabled = false,
  readonly = false,
  onChange,
}) => {
  useEffect(() => {
    createRangeDatePicker()
    changeDatePickerPosition()
    window.addEventListener('resize', changeDatePickerPosition)

    return () => {
      window.removeEventListener('resize', changeDatePickerPosition)
    }
  }, [])

  const changeDatePickerPosition = _ => {
    // End Picker만 해당
    const el_endpicker = document.querySelector(
      '.datePicker.end .tui-rangepicker'
    )
    const x_rect = el_endpicker?.getBoundingClientRect().x - 20
    if (window.innerWidth / 2 > x_rect) {
      el_endpicker.style.right = 0
    } else el_endpicker.style.right = 'unset'
  }

  const onChangeRangeDatePicker = picker => {
    const start = picker.getStartDate()
    const end = picker.getEndDate()
    onChange({ start, end })
  }
  const createRangeDatePicker = _ => {
    const today = new Date()
    const oneMonthAgo = sub(today, {
      months: 1,
    })

    const picker = new DatePicker.createRangePicker({
      startpicker: {
        date: oneMonthAgo,
        input: `#${id}_input_start`,
        container: `#${id}_calendar_start`,
      },
      endpicker: {
        date: today,
        input: `#${id}_input_end`,
        container: `#${id}_calendar_end`,
      },
      language: language,
      selectableRanges: [[new Date(1900, 1, 1), today]],
    })
    onChangeRangeDatePicker(picker)

    picker.on('change:start', _ => {
      onChangeRangeDatePicker(picker)
    })
    picker.on('change:end', _ => {
      onChangeRangeDatePicker(picker)
    })
  }

  return (
    <>
      {!!label && <span className='text-primary fw-600'> {label} </span>}
      <div className='dateRange mt-6 mb-20'>
        <div className='datePicker start'>
          <input
            type='text'
            id={`${id}_input_start`}
            className='datePicker__input'
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            inputMode='none'
          />
          <div
            id={`${id}_calendar_start`}
            className='datePicker__calendar'></div>
        </div>
        <div className='datePicker__mid'>~</div>
        <div className='datePicker end'>
          <input
            type='text'
            id={`${id}_input_end`}
            className='datePicker__input'
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            inputMode='none'
          />
          <div
            id={`${id}_calendar_end`}
            className='datePicker__calendar'></div>
        </div>
      </div>
    </>
  )
}

export default OneCalendar
