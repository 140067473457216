import { createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'

const docsWrittenAdapter = createEntityAdapter()
const docsForApprovalAdapter = createEntityAdapter()
const docsApprovalAdapter = createEntityAdapter()

const initialDocsWritten = docsWrittenAdapter.getInitialState()
const initialDocsForApproval = docsWrittenAdapter.getInitialState()
const initialDocsApproval = docsWrittenAdapter.getInitialState()

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDocsWritten: builder.query({
      query: params => ({
        url: '/approval/approvalbox/writeList',
        params,
      }),
      providesTags: ['DocsWritten'],
    }),
    // getDocsWritten2: builder.query({
    //   query: params => ({
    //     url: '/approval/approvalbox/writeList',
    //     params,
    //   }),
    //   transformResponse: response => {
    //     let results = response.map((result, index) => {
    //       return {
    //         id: index,
    //         ...result,
    //       }
    //     })
    //     return docsWrittenAdapter.setAll(initialDocsWritten, results)
    //   },
    //   providesTags: ['DocsWritten2'],
    // }),
    getDocsForApproval: builder.query({
      query: params => ({
        url: '/approval/approvalbox/approvalList',
        params,
      }),
      providesTags: ['DocsForApproval'],
    }),
    getDocsApproved: builder.query({
      query: params => ({
        url: '/approval/approvalbox/approvedList',
        params,
      }),
      providesTags: ['DocsApproved'],
    }),
  }),
})

export const {
  useGetDocsWrittenQuery,
  // useGetDocsWritten2Query,
  useGetDocsForApprovalQuery,
  useGetDocsApprovedQuery,
} = extendedApiSlice
