import React from 'react'
import ReactDOM from 'react-dom/client'

import { extendedApiSlice } from './features/auth/domainSlice'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './app/store'

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom'

import { isIPad13, isMobile } from 'react-device-detect'

import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

// import './language/i18n'

import AccountLock from './features/accountLockPage/AccountLock'
import Approval from './features/approval'
import ApprovalDetail from './features/approvalDetail/ApprovalDetail'
import ErrorPage from './features/errorPage/ErrorPage'
import InitPassword from './features/initPasswordPage/InitPassword'
import Login from './features/auth/Login'
import Main from './features/main/Main'
import MyList from './features/mylist/MyList'
import MylistDetails from './features/mylist/MyListDetail'
import { PrivateOutlet } from './features/auth/PrivateOutlet'
import Root from './features/root/View'
import Satisfaction from './features/satisfaction/Satisfaction'
import SearchRequest from './features/searchRequest/SearchRequest'
import SearchRequestDetails from './features/searchRequest/SearchRequestDetail'
import StatsTotal from './features/statsTotal/StatsTotal'
import StatsTotalDetail from './features/statsTotal/StatsTotalDetail'

import UIPage from './layouts/View'
import LoginPage from './pages/Login'
import AccountLockPage from './pages/AccountLock'
import InitPasswordPage from './pages/InitPassword'
import MainPage from './pages/DashboardService'
import ApprovalPage from './pages/Approval'
import ApprovalDetailPage from './pages/ApprovalDetail'
import SearchRequestPage from './pages/SearchRequest'
import SatisfactionPage from './pages/Satisfaction'
import SatisfactionDetailPage from './pages/SatisfactionDetail'
import StatsTotalPage from './pages/StatsTotal'
import StatsTotalDetailPage from './pages/StatsTotalDetail'
import DatailsPage from './pages/Details'
import MyListPage from './pages/MyList'
import ProcessingPage from './pages/Processing'
import WorkProcess from './features/workHistory/WorkProcess'

if (!isMobile || isIPad13) {
  window.location.href = process.env.REACT_APP_BASE_URL!
}

export const tabs = [
  { name: '작성한문서', to: 'docs-written' },
  { name: '결재할문서', to: 'docs-for-approval' },
  { name: '결재한문서', to: 'docs-approved' },
]

export const approvalTabs = tabs.map(tab => {
  const object = { ...tab }
  object.to = `approval/${object.to}`
  return object
})

const tos = tabs.map(tab => tab.to)

const camelize = (s: string) => s.replace(/-./g, x => x[1].toUpperCase())

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path='/'
        element={<Root />}
        errorElement={<ErrorPage />}>
        <Route errorElement={<ErrorPage />}>
          <Route
            path='login'
            element={<Login />}
          />
          <Route
            path='account-lock'
            element={<AccountLock />}
          />
          <Route
            path='init-passwd'
            element={<InitPassword />}
          />
          <Route element={<PrivateOutlet />}>
            <Route
              index
              loader={() => redirect('docs-for-approval')}
            />
            <Route
              path=':tabId'
              loader={({ params }) => {
                if (!tos.includes(params.tabId!)) {
                  throw new Error()
                }

                return camelize(params.tabId!)
              }}
              element={<Main />}
            />
            <Route
              path='approval'
              loader={() => redirect('docs-written')}
            />
            <Route
              path='approval/:tabId'
              loader={({ params }) => {
                if (!tos.includes(params.tabId!)) {
                  throw new Error()
                }

                return camelize(params.tabId!)
              }}
              element={<Approval />}
            />
            <Route
              path='approval/:createEmpId/:srId'
              loader={({ params }) => params}
              element={<ApprovalDetail />}
            />
            <Route
              path='search-request'
              element={<SearchRequest />}
            />
            <Route
              path='search-request/:sReqTySe/:srId'
              loader={({ params }) => params}
              element={<SearchRequestDetails />}
            />
            <Route
              path='search-request/:createCoId/:qusrId/:docId/'
              loader={({ params }) => params}
              element={<SearchRequestDetails />}
            />
            <Route
              path='satisfaction'
              element={<Satisfaction />}
            />
            <Route
              path='my-list'
              element={<MyList />}
            />
            <Route
              path='my-list-detail/:reqTySe/:srId'
              loader={({ params }) => params}
              element={<MylistDetails />}
            />
            <Route
              path='work-history/:srId'
              loader={({ params }) => camelize(params.srId!)}
              element={<WorkProcess />}
            />
            <Route
              path='stats-total'
              element={<StatsTotal />}
            />
            <Route
              path='stats-request-totlist-detail/:reqTySe/:srId'
              loader={({ params }) => params}
              element={<StatsTotalDetail />}
            />
            {/* <Route
              path='stats-request-totlist-detail/:reqTySe/:srId'
              loader={({ params }) => params}
              element={<StatsRequestTotlistDetail />}
            /> */}
          </Route>
        </Route>
      </Route>

      <Route
        path='ui-page'
        element={<UIPage />}>
        <Route
          path='login'
          element={<LoginPage />}
        />
        <Route
          path='login/lock'
          element={<AccountLockPage />}
        />
        <Route
          path='login/init'
          element={<InitPasswordPage />}
        />
        <Route
          path='main'
          element={<MainPage />}
        />
        <Route
          path='main/:id'
          element={<DatailsPage />}
        />
        <Route
          path='approval'
          element={<ApprovalPage />}
        />
        <Route
          path='approval/:id'
          loader={({ params }) => camelize(params.id!)}
          element={<ApprovalDetailPage />}
        />
        <Route
          path='search-request'
          element={<SearchRequestPage />}
        />
        <Route
          path='search-request/:id'
          element={<DatailsPage />}
        />
        <Route
          path='satisfaction'
          element={<SatisfactionPage />}
        />
        <Route
          path='satisfaction/:id'
          element={<SatisfactionDetailPage />}
        />
        <Route
          path='my-list'
          element={<MyListPage />}
        />
        <Route
          path='my-list/:id'
          element={<DatailsPage />}
        />
        <Route
          path='processing'
          element={<ProcessingPage />}
        />
        <Route
          path='stats-total'
          element={<StatsTotalPage />}
        />
        <Route
          path='stats-total/:id'
          element={<StatsTotalDetailPage />}
        />
      </Route>
    </>
  )
)

store.dispatch(extendedApiSlice.endpoints.getDomain.initiate())

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
