import CodeList from '../../components/common/TheCodeList'
import { useLoaderData } from 'react-router-dom'
import { useGetSimpleHistoryQuery } from './historySlice'
import Loader from '../../components/common/TheLoader'
import { useEffect, useState } from 'react'

export default function WorkProcess() {
  const srId = useLoaderData()

  const [isDetailLoading, setIsDetailLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      // Loader 제거
      setIsDetailLoading(false)
    }, 3000)
  }, [])

  const {
    data: simpleHistory = [],
    isLoading: isSimpleHistoryLoading,
    isSuccess: isSimpleHistorySuccess,
  } = useGetSimpleHistoryQuery({ srId })

  let newSimpleHistory = simpleHistory.entities
    ? Object.entries(simpleHistory.entities).map(([ids, entities]) => ({
        ...entities,
        processingNumber: entities.actiNm,
        content: entities.smplReqHdlCn,
        date: entities.hdlDt,
        manager: entities.userNm,
      }))
    : []

  return (
    <main className='content pt-60'>
      <div className='pb-50 pl-16 pr-16'>
        {isSimpleHistoryLoading && <Loader className='loading' />}
        {isSimpleHistorySuccess && simpleHistory.entities && (
          <CodeList
            list={newSimpleHistory}
            onlyDateManager
          />
        )}
      </div>
    </main>
  )
}
