import Code from './TheCode'
import { ContentFormNosrCnTmlSe } from '../../features/common/ContentForm'
import { decode } from 'html-entities'

const CodeList = ({
  list,
  buttonType = null,
  onlyDate = false,
  onlyDateManager = false,
  onlyAddReq = false,
}) => {
  return (
    <>
      {list.length === 0 ? (
        <div className='no__list'>작성한 문서가 없습니다.</div>
      ) : (
        list.map((item, index) => (
          <div
            key={index}
            className='code__list'>
            {item.code && (
              <>
                <Code
                  code={item.code}
                  code_number={item.code_number}
                  state={item.state}
                />
                <div className='code__list-content pb-6'>
                  {decode(item.content)}
                </div>
              </>
            )}

            {/* 추가요청 */}
            {item.addReqprocessingNumber && (
              <>
                <div className='code__item'>
                  <div className={`d-flex ${item.addReqType}`}>
                    <i className='code__icon'></i>
                    <span className='code__text'>
                      {item.addReqprocessingNumber}
                    </span>
                    <span>{item.addReqreqSe}</span>
                  </div>
                </div>
                <div className='code__list-content pb-6'>
                  {decode(item.addReqcontent)}
                </div>
              </>
            )}
            {/*  */}

            {/* 작업 처리내역 */}
            {!!item.processingNumber && (
              <>
                <span className='code__list-processing mb-6'>
                  <em>{item.processingNumber}</em>
                </span>
                <div className='whitespace-pre-wrap'>
                  <ContentFormNosrCnTmlSe srCn={decode(item.content)} />
                </div>
              </>
            )}
            {/*  */}

            {!!onlyDate ? (
              <div className='code__list-bottom'>{item.date}</div>
            ) : !!onlyDateManager ? (
              <div className='code__list-bottom2'>
                <span>{item.date}</span>
                <span>처리자 {item.manager}</span>
              </div>
            ) : !!onlyAddReq ? (
              <div className='code__list-bottom'>
                <span>{item.addReqdate}</span>
                <span>{item.addReqmanager}</span>
              </div>
            ) : (
              <>
                {!!item.classification && item.code !== 'deployment' && (
                  <div className='code__list-bottom'>
                    <span>요청분류</span>
                    <span>{item.classification}</span>
                  </div>
                )}

                <div className='code__list-bottom'>
                  <span>요청일시</span>
                  <span>{item.date}</span>
                </div>

                {!!item.manager && (
                  <div className='code__list-bottom'>
                    <span>처리자</span>
                    <span>{item.manager}</span>
                  </div>
                )}
              </>
            )}
          </div>
        ))
      )}
    </>
  )
}

export default CodeList
