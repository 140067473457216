import { apiSlice } from '../../app/services/apiSlice'

//결재 (승인, 반려, 회수 Slice)
export interface returnResponse {
  pCoId: string
  pCreateEmpId: string
  pApprId: string
  pDocId: string
  pApprDetailId: string
  pApprTy: string
  pReturnDtlId: string
  pReturnEmpId: string
  pReturnDepId: string
  pApprReply: string
  pApprEmpCoId: string
  pApprEmpId: string
  pCurStatus: string
}

export interface agreeResponse {
  pCoId: string
  pCreateEmpId: string
  pApprId: string
  pDocId: string
  pApprDetailId: string
  pApprTy: string
  pApprReply: string
  pApprEmpCoId: string
  pApprEmpId: string
  pCurStatus: string
}

export interface withDrawResponse {
  pCoId: string
  pCreateEmpId: string
  pApprId: string
  pApprDetailId: string
}

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    apprReturn: builder.mutation<returnResponse, Partial<returnResponse>>({
      query: body => ({
        url: '/approval/approvalbox/apprReturn',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(body)).toString(),
      }),
      invalidatesTags: ['Return'],
    }),
    apprAgree: builder.mutation<agreeResponse, Partial<agreeResponse>>({
      query: body => ({
        url: 'approval/approvalbox/apprActAgree',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(body)).toString(),
      }),
      invalidatesTags: ['Agree'],
    }),
    withDraw: builder.mutation<withDrawResponse, Partial<withDrawResponse>>({
      query: body => ({
        url: '/approval/approvalbox/apprWithDraw',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(body)).toString(),
      }),
      invalidatesTags: ['WithDraw'],
    }),
  }),
})

export const {
  useApprReturnMutation,
  useApprAgreeMutation,
  useWithDrawMutation,
} = extendedApiSlice
