import { format, sub } from 'date-fns'
import { useEffect, useState } from 'react'
import { Link, useLoaderData } from 'react-router-dom'
import BarChart from '../../components/common/TheBarChart'
import DoughnutChart from '../../components/common/TheDoughnutChart'
import Loader from '../../components/common/TheLoader'
import Tabs from '../../components/tabs/Tabs'
import { tabs } from '../../index'
import {
  useGetDocsApprovedQuery,
  useGetDocsForApprovalQuery,
  useGetDocsWrittenQuery,
} from '../approval/approvalSlice'

import { useGetDayReqRatQuery, useGetmembProcChartQuery } from './chartSlice'
import { useAuth } from '../../hooks/useAuth'
import { useGetDeptUserListQuery } from '../statsTotal/totalRequestSlice'

const today = format(new Date(), 'yyyyMMdd')
const previousDate = format(
  sub(new Date(), {
    months: 1,
  }),
  'yyyyMMdd'
)

export default function Main() {
  const { user } = useAuth()

  //서비스 조직 리스트
  const [deptUserList, setDeptUserList] = useState({
    coId: user.domain,
    perPage: 1,
    page: 1,
  })
  let {
    data: deptUserLists = {},
    isLoading: isDeptUserListLoading,
    isSuccess: isDeptUserListSuccess,
  } = useGetDeptUserListQuery({})

  let checkDeptUser
  let existDeptUser
  if (deptUserLists && deptUserLists.entities) {
    checkDeptUser = Object.entries(deptUserLists.entities).map(
      ([ids, entities]) => {
        const existId = entities.userId
        if (existId === user.id) {
          existDeptUser = 'true'
        }
      }
    )
  }

  const { data: membProcChart = [] } = useGetmembProcChartQuery({
    interval: 1,
    ReqTySe: '',
    top: 5,
  })
  const { data: dayReqRat = [] } = useGetDayReqRatQuery({
    interval: 1,
    top: 5,
    coId: '',
    ReqTySe: '',
  })

  // ---- (차트 서비스) 담당자 별 처리현황 - horizon bar
  const userNm = membProcChart?.userNm ?? ''
  const cntDone = membProcChart?.cntDone ?? ''

  const { data: membProcChartBuf } = useGetmembProcChartQuery({
    interval: 1,
    ReqTySe: '',
    top: 5,
    userNm: userNm,
    cntDone: cntDone,
  })

  let membProcChartData = {}

  // 담당자 별 완료 횟수
  let membCntDone = []
  // 담당자 이름
  let membUserNm = []
  let sum = 0

  if (
    membProcChartBuf?.membProcChart &&
    membProcChartBuf.membProcChart.length > 0
  ) {
    for (let i = 0; i < membProcChartBuf.membProcChart.length; i++) {
      // 담당자 별 완료 횟수
      membCntDone[i] = membProcChartBuf['membProcChart'][i].cntDone || 0
      membUserNm[i] = membProcChartBuf['membProcChart'][i].userNm || ''
      // 전체 담당자별 완료 횟수 총합
      sum += membCntDone[i]
    }

    // 담당자 별 처리현황(실제 Chart에 보여지는 값)
    let procMemb = []
    for (let i = 0; i < membProcChartBuf.membProcChart.length; i++) {
      procMemb[i] = Math.round(((membCntDone[i] / sum) * 100 * 10) / 10)
    }

    membProcChartData = {
      membUserNm: membUserNm,
      procMemb: procMemb,
    }
  }

  // ---- (차트 서비스) 당일 요청/처리 건수 - donut
  let dayReqRatData = {}
  const req = dayReqRat?.req ?? ''
  const proc = dayReqRat?.proc ?? ''

  const { data: dayReqRatBuf = [] } = useGetDayReqRatQuery({
    interval: 1,
    top: 5,
    coId: '',
    ReqTySe: '',
    proc: proc,
    req: req,
  })

  // NaN 또는 undefined가 발생할 경우는 값을 0으로 설정해줌
  // 요청(total) = 요청 + 처리
  const request =
    (dayReqRatBuf['dayReqRat']?.req || 0) +
    (dayReqRatBuf['dayReqRat']?.proc || 0)
  // 처리
  const process = dayReqRatBuf['dayReqRat']?.proc || 0
  // 처리율(%) = (처리 / 요청(total)) * 100
  const processingRate =
    request !== 0 ? Math.floor((process / request) * 100) : 0
  dayReqRatData = {
    request: request,
    process: process,
    processingRate: processingRate,
  }

  const todayTotal = {
    request: dayReqRatData.request,
    process: dayReqRatData.process,
    processingRate: processingRate,
  }

  const doughnutData = [
    todayTotal.processingRate,
    100 - todayTotal.processingRate,
  ]

  const barLabel = membProcChartData.membUserNm
  const barData = membProcChartData.procMemb

  // Tabs
  const tabId = useLoaderData()

  const {
    data: docsWritten = [],
    isLoading: isDocsWrittenLoading,
    isFetching: isDocsWrittenFetching,
    isSuccess: isDocsWrittenSuccess,
    isError: isDocsWrittenError,
    error: docsWrittenError,
  } = useGetDocsWrittenQuery({
    searchReqTySe: '',
    searchAppStatusCod: 1,
    fromDate: previousDate,
    toDate: today,
    searchType: 0,
    searchWord: '',
    searchDateUse: true,
    perPage: 1,
    page: 1,
  })

  const {
    data: docsForApproval = [],
    isLoading: isDocsForApprovalLoading,
    isFetching: isDocsForApprovalFetching,
    isSuccess: isDocsForApprovalSuccess,
    isError: isDocsForApprovalError,
    error: docsForApprovalError,
  } = useGetDocsForApprovalQuery({
    searchReqTySe: '',
    searchAppStatusCod: 1,
    fromDate: previousDate,
    toDate: today,
    searchType: 0,
    searchWord: '',
    // searchDateUse: true,
    perPage: 1,
    page: 1,
  })

  const {
    data: docsApproved = [],
    isLoading: isDocsApprovedLoading,
    isFetching: isDocsApprovedFetching,
    isSuccess: isDocsApprovedSuccess,
    isError: isDocsApprovedError,
    error: docsApprovedError,
  } = useGetDocsApprovedQuery({
    searchReqTySe: '',
    searchAppStatusCod: 1,
    fromDate: previousDate,
    toDate: today,
    searchType: 0,
    searchWord: '',
    searchDateUse: true,
    perPage: 1,
    page: 1,
  })

  // ---- 결재 보관함(작성한문서, 결재할문서, 결재한문서)
  const contents = {
    docsWritten,
    docsForApproval,
    docsApproved,
  }

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      // Loader 제거
      setIsLoading(false)
    }, 3000)
  }, [])

  let LoadingStatus
  if (
    isDocsWrittenLoading ||
    isDocsForApprovalLoading ||
    isDocsApprovedLoading
  ) {
    LoadingStatus = <Loader className='loading' />
  } else {
    LoadingStatus = (
      <Tabs
        tabId={tabId}
        tabs={tabs}
        contents={contents}
      />
    )
  }

  return (
    <main className='content'>
      {existDeptUser && existDeptUser === 'true' && (
        <>
          <div className='chart__block'>
            {/* 당일 요청/처리 현황 */}
            <div className='chart__card'>
              <span className='chart__title'>당일 요청/처리 현황</span>
              <div className='chart__center'>
                <span>{todayTotal.processingRate}%</span>
                <span>처리율</span>
              </div>
              <div className='doughnut-chart'>
                <DoughnutChart
                  className='ma-auto mb-10 mt-25'
                  id='exDoughnutChart'
                  chartData={doughnutData}
                />
              </div>
              <div className='chart__card-bottom'>
                <div>
                  <span>{todayTotal.request}</span>
                  <span className='mt-2'>요청</span>
                </div>
                <div>
                  <span>{todayTotal.process}</span>
                  <span className='mt-2'>처리</span>
                </div>
              </div>
            </div>

            {/* 담당자별 현황 */}
            <div className='chart__card ml-20 mr-20'>
              <span className='chart__title'>담당자별 처리현황</span>
              <span
                style={{
                  fontSize: '16px',
                }}>
                &nbsp;Top5
              </span>
              <BarChart
                className='ma-auto mt-3 pb-8'
                id='exBarChart'
                chartLabel={barLabel}
                chartData={barData}
              />
            </div>
          </div>
        </>
      )}

      <div className={existDeptUser ? 'pt-10 pl-16 pr-16' : 'pl-16 pr-16'}>
        <h2 className={existDeptUser ? 'pt-20 pb-24' : 'pt-12 pb-24'}>
          <Link
            to='/approval'
            className='text-black-95'>
            결재 문서함 &gt;
          </Link>
        </h2>
      </div>
      {LoadingStatus}
    </main>
  )
}
