import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Form, useNavigate } from 'react-router-dom'

import { useLoginMutation } from './authSlice'
import { selectDomain } from './domainSlice'

import { Buffer } from 'buffer'

import Input from '../../components/common/TheInput'
import Button from '../../components/common/TheButton'
import Dropdown from '../../components/common/TheSelect'
import Loader from '../../components/common/TheLoader'

export default function Login() {
  const [idValue, setIdValue] = useState('')
  const [pwValue, setPwValue] = useState('')
  const [domainValue, setDomainValue] = useState('')

  const [formState, setFormState] = useState({
    username: '',
    password: '',
    domain: '',
  })

  const domains = useSelector(selectDomain)

  const domainOptions = Object.entries(domains.entities).map(
    ([ids, entities]) => ({
      label: entities.coNm,
      value: entities.coId,
    })
  )

  const navigate = useNavigate()

  const [login, { isLoading: isLoginLoading, isSuccess: isLoginSuccess }] =
    useLoginMutation()

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      username: Buffer.from(idValue).toString('base64'),
      password: Buffer.from(pwValue).toString('base64'),
      domain: domainValue,
    }))
  }, [idValue, pwValue, domainValue])

  useEffect(() => {
    domainValue || setDomainValue(domainOptions[0]?.value)
  }, [domainValue, domainOptions])

  const [idError, setIdError] = useState(false)
  const [passError, setPassError] = useState(false)
  const [idMessage, setIdMessage] = useState('')
  const [passMessage, setPassMessage] = useState('')

  const preventInput = event => {
    const regExp = /[^0-9a-zA-Z`~!@#$%^&*()\-_=+\\|[\]{};:'",.<>/?]/g
    const enteredInput = event.target
    if (regExp.test(enteredInput.value)) {
      enteredInput.value = enteredInput.value.replace(regExp, '')
    }
  }

  const keyupHandler = e => {
    preventInput(e)
  }

  const blurHandler = e => {
    preventInput(e)
  }

  return (
    <main className='content login'>
      <h1 className='logo'>USTRA ITSM</h1>
      <Form className='loginBox'>
        {/* <input type="text" placeholder="아이디" className="login__input" />
        <input type="text" placeholder="비밀번호" className="login__input" />
        <button className="btn is-primary is-large width-100">로그인</button> */}

        {isLoginLoading && <Loader className='loading' />}

        <Input
          type='text'
          className='mb-10 mt-10'
          value={idValue}
          onChange={e => setIdValue(e.target.value.replace(' ', ''))}
          placeholder='아이디'
          error={idError}
          maxLength={50}
          onKeyUp={keyupHandler}
          onBlur={blurHandler}
          errorText={idMessage}
        />
        <Input
          // type="paddword"
          className='mb-10 mt-10'
          value={pwValue}
          type='password'
          maxLength={50}
          autoComplete='off'
          onChange={e => setPwValue(e.target.value.replace(' ', ''))}
          placeholder='비밀번호'
          onKeyUp={keyupHandler}
          onBlur={blurHandler}
          error={passError}
          errorText={passMessage}
        />
        <Dropdown
          label='회사구분'
          options={domainOptions}
          onChange={e => setDomainValue(e.curr.value)}
        />

        <Button
          onClick={async event => {
            event.preventDefault()

            setIdError(false)
            setPassError(false)

            setFormState({
              username: Buffer.from(idValue).toString('base64'),
              password: Buffer.from(pwValue).toString('base64'),
              domain: domainValue,
            })

            var emailRegExp =
              /^([A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+\.[a-zA-Z]{2,3}){1,50}$/

            if (!idValue) {
              setIdError(true)
              setIdMessage('아이디를 입력해주세요.')
              return
            } else if (!pwValue) {
              setPassError(true)
              setPassMessage('비밀번호를 입력해주세요.')
              return
            } else if (!domainValue) {
              setIdError(true)
              setIdMessage('')
              setPassError(true)
              setPassMessage('회사 구분을 입력해주세요.')
              return
            }
            // 이메일 로그인으로 바뀌면 사용할 구문
            // else if (idValue && !emailRegExp.test(idValue)) {
            //   setIdError(true)
            //   setIdMessage('이메일 형식으로 입력해주세요.')
            //   return
            // }

            try {
              await login(formState).unwrap()
              navigate('/')
            } catch (err) {
              setIdError(false)
              setPassError(false)

              let errormsg = err.data?.errormsg
              let errorStatus = err?.status

              if (errorStatus === 403) {
                navigate('/account-lock', {
                  state: {
                    userId: idValue,
                    coId: domainValue,
                  },
                })
              } else if (errorStatus === 302) {
                navigate('/init-passwd', {
                  state: {
                    userId: idValue,
                    coId: domainValue,
                  },
                })
              } else {
                setIdError(false)
                setPassError(false)

                if (errormsg) {
                  setIdError(true)
                  setIdMessage('')
                  setPassError(true)
                  setPassMessage(errormsg.replaceAll('<br/>', ' '))
                } else {
                  setIdError(true)
                  setIdMessage('')
                  setPassError(true)
                  setPassMessage(
                    '로그인 과정에서 오류가 발생했습니다. 다시 시도해주세요.'
                  )
                }
              }
            }
          }}>
          로그인
        </Button>
      </Form>
      <footer className='footer'>
        <p className='footer__copyright'>
          COPYRIGHT © 2023 GS ITM. ALL RIGHTS RESERVED.
        </p>
      </footer>
    </main>
  )
}
