import { useEffect, useState } from 'react'

const ScrollTop = () => {
  const [isShowTopBtn, setIsShowTopBtn] = useState()

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    window.scrollY >= 20 ? setIsShowTopBtn(true) : setIsShowTopBtn(false)
  }

  const onClickTopButton = _ => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <i
      className={`moveTopBtn ${isShowTopBtn ? 'on' : ''}`}
      onClick={onClickTopButton}
    />
  )
}

export default ScrollTop
