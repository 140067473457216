import { decode } from 'html-entities'
import Table from './TheTable'

const Card = ({ title /* require */, content, items, children }) => {
  return (
    <div className='white__card'>
      <span className='white__card-title'>{title}</span>
      {!!content && (
        <span className='white__card-content whitespace-pre-wrap'>
          {decode(content)}
        </span>
      )}

      {!!items &&
        items.map((item, index) => (
          <div
            className='white__card-wrapper'
            key={index}>
            <div className='white__card-item'>
              <span>{item.title}</span>
              {!!item.content && (
                <div className='whitespace-pre-wrap'>
                  {decode(item.content)}
                </div>
              )}
            </div>

            {/* Table */}
            {!!item.headerList && (
              <Table
                headerList={item.headerList}
                data={item.list}
              />
            )}
          </div>
        ))}

      {/* 그 외의 내용 */}
      {children}
    </div>
  )
}

export default Card
