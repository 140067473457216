import { Link } from 'react-router-dom'
import Button from '../components/common/TheButton'
import Card from '../components/common/TheCard'
import Input from '../components/common/TheInput'
import OrderApproval from '../components/common/TheOrderApproval'
import ProgressApproval from '../components/common/TheProgressApproval'
import RequestContent from '../components/common/TheRequestContent'

import { useLoaderData, useOutletContext } from 'react-router-dom'

export default function StatsTotalDetailPage() {
  const outletProps = useOutletContext()
  const srId = useLoaderData()

  const progressData = [
    {
      progress: 'did',
      status: '요청접수',
    },
    {
      progress: 'do',
      status: '1차 처리',
    },
    {
      progress: 'will',
      status: '2차 처리 요청접수',
    },
    {
      progress: 'will',
      status: '3차 처리 요청접수',
    },
    {
      progress: 'will',
      status: '4차 처리 요청접수',
    },
  ]

  const orderData = [
    {
      stepType: 'approval',
      stepText: '승인',
      status: '작성자',
      name: '홍길동 팀원',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'proceed',
      stepText: '진행중',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리가나다라마바사',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'reject',
      stepText: '반려',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'waiting',
      stepText: '대기',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'agreement',
      stepText: '합의',
      status: '합의',
      name: '박 하늘별님구름햇님보다사랑스러우리',
      date: '2022-07-18 15:35',
    },
    {
      stepType: 'dismiss',
      stepText: '기각',
      status: '합의',
      name: '박하나님의자녀예쁘고진실되고이해심많게자라라',
      date: '2022-07-18 15:35',
    },
  ]

  const requestContent = {
    code: '단순요청',
    code_number: 'SR05000003590',
    title: 'ITSM 모바일 채널을 만들어주세요.',
    content:
      '요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.요청 내용입니다.',
    classification: '사용문의',
    clientClassification: '사용문의',
    client: 'GS ITM',
    reqManager: '이길동 매니저',
    /* ----- */
    requestService: 'SharedService > U.STRA ITSM > ITSM',
    requestDate: '2023-04-01 09:30',
    receptionist: '홍길동 매니저',
    completeHopeDate: '2023-04-01 09:30',
    finalDate: '2023-04-01 09:30',
    completeDate: '2023-04-01 09:30',
    manager: '홍길동 매니저',
    addContent: '보안내용 입니다.',
    attatchFile: '요청입니다.xlsx',
  }

  const processingItem = [
    {
      title: '작업내용 Text',
      content: '요청사항을 처리완료 하였습니다.',
    },
    {
      title: '작업내용 Table',
      headerList: [
        { name: '단계', value: 'step', width: '70px' },
        { name: '처리자', value: 'manager', width: '70px' },
        { name: '처리내역', value: 'content' },
      ],
      list: [
        {
          step: '분석',
          manager: '홍길동',
          content: '처리내역을 입력하세요.',
        },
        {
          step: '설계',
          manager: '홍길동',
          content: '처리내역을 입력하세요.',
        },
        {
          step: '형상등록',
          manager: '홍길동',
          content: '처리내역을 입력하세요.',
        },
      ],
    },
  ]

  // console.log('라우터에서 받아온 페이지 파라미터: '+srId);
  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div className='bg-blue-100 pb-8'>
          <ProgressApproval progressData={progressData} />
        </div>
        <div className='bg-blue-100 pb-8'>
          <OrderApproval orderData={orderData} />
        </div>

        <div className='bg-blue-100'>
          <div className='bg-white pb-12 pl-16 pr-16 pt-32'>
            <h2
              className='pb-12'
              style={{ borderBottom: '1px solid #f1f1f1' }}>
              요청 내용
            </h2>
          </div>

          <RequestContent item={requestContent} />
          <div className='request__card'>
            <div className='request__card-item pt-0'>
              <span>의견</span>
              <Input
                type='textarea'
                className='mt-5'
              />
            </div>
          </div>
        </div>
        
        <div className='bg-blue-100 pb-24 pl-16 pr-16 pt-24'>
          <Card
            title='처리내용'
            items={processingItem}>
            <Link to='/ui-page/processing'>
              <Button
                type='btn'
                variant='secondary'>
                작업 처리내역
              </Button>
            </Link>
          </Card>

          <Card
            title='결과통보'
            items={[
              {
                title: '고객요청 답변내용',
                content:
                  '이길동 매니저님께서 요청하신 내용을 처리 완료되었습니다.',
              },
            ]}
          />
          <Card
            title='고객만족도'
            items={[
              {
                title: '고객만족도',
                content: '매우만족',
              },
              {
                title: '만족도의견',
                content: '바쓰진 와중에 빠른 처리 감사합니다.',
              },
            ]}
          />
          <Card
            title='반려의견'
            items={[
              {
                title: '반려의견',
                content: '-',
              },
            ]}
          />
          <Card title='추가요청'></Card>
        </div>
      </div>
    </main>
  )
}
