import {
  ArcElement,
  Chart as ChartJS,
  // CategoryScale,
  LinearScale,
  // BarElement,
  Title,
  Tooltip,
  // Legend,
} from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  ArcElement,
  // CategoryScale,
  LinearScale,
  // BarElement,
  Title,
  Tooltip,
  // Legend,
  ChartDataLabels
)

const BarChart = ({
  className: parentClass,
  id,
  chartLabel = [],
  chartData = [], // array,
  backgroundColor,
}) => {
  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        border: { display: false }, // x축 가장자리 hide
        grid: { color: '#F8F8F8' }, // x축 border 색상
        ticks: { display: false }, // x축 label hide,
        max: Math.max(...chartData) + 45, // 최대값 설정 (data 중 최댓값 찾아서 +10)
      },
      y: {
        border: { color: '#F8F8F8' },
        grid: { display: false },
        ticks: {
          color: '#000000',
          font: {
            family: 'Pretendard',
            size: 13,
            weight: 'normal',
            lineHeight: 1.8,
          },
          padding: 4,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: true,
        color: backgroundColor || '#00D5A2',
        font: { family: 'Pretendard', size: 13, weight: 500 },
        formatter: value => {
          return value + '%'
        },
        anchor: 'end',
        offset: -40,
        align: 'start',
      },
    },
  }

  const base_data = {
    labels: chartLabel,
    datasets: [
      {
        data: chartData,
        backgroundColor: backgroundColor || '#00D5A2',
        borderWidth: 0,
        borderRadius: 5,
        barThickness: 15,
      },
    ],
  }

  return (
    <Bar
      className={parentClass}
      id={id}
      data={base_data}
      options={options}
      height='140px'
      width='144px'
      style={{ marginLeft: '-4px' }}
    />
  )
}

export default BarChart
