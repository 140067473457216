import CodeList from '../components/common/TheCodeList'
import Button from '../components/common/TheButton'

export default function Processing() {
  const list = [
    {
      processingNumber: '1',
      content:
        '1차 처리를 완료하였습니다. 1차 처리를 완료하였습니다. 1차 처리를 완료하였습니다.',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      processingNumber: '2',
      content: '2차 처리를 완료하였습니다.',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      processingNumber: '1',
      content: '1차 처리를 완료하였습니다.....',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      processingNumber: '3',
      content: '3차 처리를 완료',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
    {
      processingNumber: '4',
      content: '4차 처리를 완료',
      date: '2023-03-20 16:45',
      manager: '김길동 매니저',
    },
  ]

  return (
    <main className='content pt-60'>
      <div className='pb-50 pl-16 pr-16'>
        <CodeList
          list={list}
          onlyDateManager
        />

        {list.length === 0 ? null : (
          <Button
            type='shuffle'
            variant='secondary'
            overrideStyle={{
              width: '100%',
              height: '46px',
              marginTop: '14px',
              marginLeft: 0,
              fontSize: '15px',
              color: '#333333',
              border: '1px solid #cccccc',
            }}>
            더보기
            <span className='arrow__icon expand' />
          </Button>
        )}
      </div>
    </main>
  )
}
