import { decode } from 'html-entities'
import { useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'

import $ from 'jquery'
window.$ = window.jQuery = $

require('jquery-ui-sortable')
require('formBuilder')
require('formBuilder/dist/form-render.min.js')

// Form Builder
function FormRenderer({ content }) {
  const formRef = useRef(null)
  if (Array.isArray(content)) {
    content = content.map(item => {
      if (item.userData) {
        item.userData = item.userData.map(data => {
          data = decode(data)
          return data
        })
        return item
      } else {
        return item
      }
    })
  }

  useEffect(() => {
    if (content) {
      let formRenderOpts
      if (Array.isArray(content)) {
        formRenderOpts = {
          dataType: 'json',
          formData: content,
          readOnly: true,
        }
      } else {
        formRenderOpts = {
          dataType: 'json',
          formData: decode(content),
          readOnly: true,
        }
      }
      const renderedForm = $(formRef.current)
      renderedForm.formRender(formRenderOpts)
      renderedForm.find('*').attr('disabled', true)
    }
  }, [content])

  return <div ref={formRef} />
}

function IsJsonString(str) {
  try {
    let json = JSON.parse(str)
    return typeof json === 'object'
  } catch (e) {
    return false
  }
}

export function ContentForm({ srCnTmlSe, srCn }) {
  const module = {
    toolbar: false,
  }

  // form, 텍스트, 에디터 내용 체크하는 함수
  function checkContentsType(srCnTmlSe, srCn) {
    if (srCnTmlSe === 'CM100602') {
      return <FormRenderer content={srCn} />
    } else if (srCnTmlSe === 'CM100603') {
      let modifiedContent
      if (IsJsonString(srCn)) {
        const content = JSON.parse(srCn).ops
        modifiedContent = content.map(item => {
          if (item?.insert?.image) {
            item.insert.image =
              process.env.REACT_APP_BASE_URL + item.insert.image
          }
          return item
        })
      } else {
        modifiedContent = srCn
      }

      return (
        <ReactQuill
          defaultValue={modifiedContent}
          readOnly='false'
          modules={module}
        />
      )
    } else {
      return srCn
    }
  }

  return (
    <div className='whitespace-pre-wrap'>
      {checkContentsType(srCnTmlSe, decode(srCn))}
    </div>
  )
}

// form builder가 없으며,
// 내용 유형이 텍스트 & 에디터만 있는 경우(srCnTmlSe 없음)
export function ContentFormNosrCnTmlSe({ srCn }) {
  const module = {
    toolbar: false,
  }

  // 텍스트, 에디터 내용 표출하는 함수
  function returnContents(srCn) {
    let modifiedContent
    if (IsJsonString(srCn)) {
      const content = JSON.parse(srCn).ops
      modifiedContent = content.map(item => {
        if (item?.insert?.image) {
          item.insert.image = process.env.REACT_APP_BASE_URL + item.insert.image
        }
        return item
      })
    } else {
      modifiedContent = srCn
    }

    return (
      <ReactQuill
        defaultValue={modifiedContent}
        readOnly='false'
        modules={module}
      />
    )
  }

  return <div>{returnContents(decode(srCn))}</div>
}

// srCnTmlSe 없는 경우(= 템플릿 유형 파라미터가 없는 경우 form, 텍스트, 에디터 내용 체크하는 함수)
export function ContentFormNullsrCnTmlSe({ srCn }) {
  const module = {
    toolbar: false,
  }

  // form, 텍스트, 에디터 내용 체크하는 함수
  function checkContentsType(srCn) {
    try {
      let json = JSON.parse(srCn)
      if (Array.isArray(json)) {
        // 폼빌더의 경우 json 이면서, 배열안의 객체
        return <FormRenderer content={srCn} />
      } else if (typeof json === 'object') {
        // 에디터의 경우 json 이면서, 단일 객체
        let modifiedContent
        const content = json.ops
        modifiedContent = content.map(item => {
          if (item?.insert?.image) {
            item.insert.image =
              process.env.REACT_APP_BASE_URL + item.insert.image
          }
          return item
        })

        return (
          <ReactQuill
            defaultValue={modifiedContent}
            readOnly='false'
            modules={module}
          />
        )
      }
    } catch (error) {
      // json 상태가 아닐 때 텍스트 내용 표출
      return srCn
    }
  }

  return (
    <div className='whitespace-pre-wrap'>{checkContentsType(decode(srCn))}</div>
  )
}
