import { useLoaderData } from 'react-router-dom'
import Tabs from '../../components/tabs/Tabs'
import { approvalTabs as tabs } from '../../index'
import { format, sub } from 'date-fns'
import { useEffect, useState } from 'react'
import Loader from '../../components/common/TheLoader'

import {
  useGetDocsApprovedQuery,
  useGetDocsForApprovalQuery,
  // useGetDocsWritten2Query,
  useGetDocsWrittenQuery,
} from './approvalSlice'

const today = format(new Date(), 'yyyyMMdd')
const previousDate = format(
  sub(new Date(), {
    months: 1,
  }),
  'yyyyMMdd'
)

export default function Approval() {
  const tabId = useLoaderData()

  const {
    data: docsWritten = [],
    isLoading: isDocsWrittenLoading,
    isFetching: isDocsWrittenFetching,
    isSuccess: isDocsWrittenSuccess,
    isError: isDocsWrittenError,
    error: docsWrittenError,
  } = useGetDocsWrittenQuery({
    searchReqTySe: '',
    searchAppStatusCod: 1,
    fromDate: previousDate,
    toDate: today,
    searchType: 0,
    searchWord: '',
    searchDateUse: true,
    perPage: 1,
    page: 1,
  })

  // const { data: docsWritten2 = [] } = useGetDocsWritten2Query({
  //   searchReqTySe: '',
  //   searchAppStatusCod: 1,
  //   fromDate: previousDate,
  //   toDate: today,
  //   searchType: 0,
  //   searchWord: '',
  //   searchDateUse: true,
  //   perPage: 1,
  //   page: 1,
  // })

  // const docsWrittenCode = docsWritten2.entities
  //   ? Object.entries(docsWritten2.entities).map(([ids, entities]) => ({
  //       ...entities,
  //     }))
  //   : []

  // console.log('old', docsWritten)
  // console.log('new', docsWrittenCode)

  const {
    data: docsForApproval = [],
    isLoading: isDocsForApprovalLoading,
    isFetching: isDocsForApprovalFetching,
    isSuccess: isDocsForApprovalSuccess,
    isError: isDocsForApprovalError,
    error: docsForApprovalError,
  } = useGetDocsForApprovalQuery({
    searchReqTySe: '',
    searchAppStatusCod: 1,
    fromDate: previousDate,
    toDate: today,
    searchType: 0,
    searchWord: '',
    // searchDateUse: true,
    perPage: 1,
    page: 1,
  })

  const {
    data: docsApproved = [],
    isLoading: isDocsApprovedLoading,
    isFetching: isDocsApprovedFetching,
    isSuccess: isDocsApprovedSuccess,
    isError: isDocsApprovedError,
    error: docsApprovedError,
  } = useGetDocsApprovedQuery({
    searchReqTySe: '',
    searchAppStatusCod: 1,
    fromDate: previousDate,
    toDate: today,
    searchType: 0,
    searchWord: '',
    searchDateUse: true,
    perPage: 1,
    page: 1,
  })

  // ---- 결재 보관함(작성한문서, 결재할문서, 결재한문서)
  const contents = {
    docsWritten,
    docsForApproval,
    docsApproved,
  }

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      // Loader 제거
      setIsLoading(false)
    }, 3000)
  }, [])

  let LoadingStatus
  if (
    isDocsWrittenLoading ||
    isDocsForApprovalLoading ||
    isDocsApprovedLoading
  ) {
    LoadingStatus = <Loader className='loading' />
  } else {
    LoadingStatus = (
      <Tabs
        tabId={tabId}
        tabs={tabs}
        contents={contents}
      />
    )
  }

  return <main className='content pt-60'>{LoadingStatus}</main>
}
