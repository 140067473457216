import { useCallback, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import {
  useGetDeptUserListQuery,
  useGetReqTypeQuery,
  useGetTotalRequestListQuery,
} from './totalRequestSlice'

import { format, sub } from 'date-fns'
import Alert from '../../components/common/TheAlert'
import Button from '../../components/common/TheButton'
import Checkbox from '../../components/common/TheCheckbox'
import CodeList from '../../components/common/TheCodeListForRequest'
import Input from '../../components/common/TheInput'
import Loader from '../../components/common/TheLoader'
import OneCalendar from '../../components/common/TheOneCalendar'
import Radio from '../../components/common/TheRadio'
import Dropdown from '../../components/common/TheSelect'
import ErrorPageNoAuth from '../errorPage/ErrorPageNoAuth'

export default function StatsTotal() {
  const { user } = useAuth()
  const [isExpand, setIsExpand] = useState(false)

  //서비스 조직 리스트
  const [deptUserList, setDeptUserList] = useState({
    coId: user.domain,
    perPage: 1,
    page: 1,
  })
  let {
    data: deptUserLists = {},
    isLoading: isDeptUserListLoading,
    isSuccess: isDeptUserListSuccess,
  } = useGetDeptUserListQuery({})

  let checkDeptUser
  let existDeptUser
  if (deptUserLists && deptUserLists.entities) {
    checkDeptUser = Object.entries(deptUserLists.entities).map(
      ([ids, entities]) => {
        const existId = entities.userId
        if (existId === user.id) {
          existDeptUser = 'true'
        }
      }
    )
  }

  //요청유형
  const [reqType, setReqType] = useState('')
  let {
    data: reqTyeps = {},
    isLoading: isReqTypeLoading,
    isSuccess: isReqTypeSuccess,
  } = useGetReqTypeQuery({
    cmmCodList: [
      {
        cmmCodCl: '0175',
        companyAt: false,
        useAt: 'Y',
      },
    ],
  })

  let reqTypeOptions
  if (isReqTypeLoading) {
    reqTypeOptions = [
      {
        lable: '로딩중...',
        value: '',
      },
    ]
  } else if (isReqTypeSuccess) {
    reqTypeOptions = Object.entries(reqTyeps.entities).map(
      ([ids, entities]) => ({
        label: entities.cmmCodNm,
        value: entities.cmmCodCl,
      })
    )
  }

  //요청일
  const today = format(new Date(), 'yyyy-MM-dd')
  const oneMonthAgo = format(
    sub(new Date(), {
      months: 1,
    }),
    'yyyy-MM-dd'
  )

  const [rangeObj, setRangeObj] = useState({
    start: oneMonthAgo,
    end: today,
  })

  const onChangeRangePicker = ({ start, end }) => {
    setRangeObj({
      start: format(start, 'yyyy-MM-dd'),
      end: format(end, 'yyyy-MM-dd'),
    })
  }

  //검색어
  const searchTypeOptions = [
    { label: '요청번호', value: '0' },
    { label: '요청자', value: '1' },
    { label: '요청제목', value: '2' },
    { label: '대표요청번호', value: '3' },
  ]

  const [searchType, setSearchType] = useState(searchTypeOptions[0].value)
  const [inputValue, setInputValue] = useState('')

  const onClickExpandButton = _ => {
    setIsExpand(!isExpand)
  }

  //요청기간 타입
  const [periodType, setPeriodType] = useState('0254')
  const periodTypeChange = e => {
    setPeriodType(e.target.value)
  }

  //조회
  const [defaultPayload, setDefaultPayload] = useState({
    searchCoId: user.domain,
    searchComplyWith: '',
    servicesWord: '',
    searchServIds: '',
    searchType: '0',
    searchWord: '',
    searchReqTySe: '',
    searchReqPathSe: '',
    searchRegTySe: '',
    searchPeriodType: '0254',
    startDate: rangeObj.start,
    endDate: rangeObj.end,
    searchTypeTime: 1,
    searchHdlStatuses: '0145,0146,0147,0150',
    perPage: 1,
    page: 1,
  })

  //처리상태
  let stringSearchHdlStatusList = defaultPayload.searchHdlStatuses.split(',')
  const [checkedList, setCheckedList] = useState(stringSearchHdlStatusList)

  let newCheckedList = ''
  const checkedHandler = (checked, code) => {
    if (checked) {
      newCheckedList = [...checkedList, code]
      setCheckedList(newCheckedList)
    } else {
      newCheckedList = checkedList.filter(el => el !== code)
      setCheckedList(newCheckedList)
    }
  }

  //Alert
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const onErrorAlertState = useCallback(value => {
    setIsShowErrorAlert(value)
  }, [])

  const {
    data: totalRequestList = [],
    isLoading: isTotalRequestListLoading,
    isSuccess: isTotalRequestListSuccess,
    isFetching: isTotalRequestFetching,
  } = useGetTotalRequestListQuery(defaultPayload)

  const onClickSearchButton = e => {
    if (checkedList.length === 0) {
      setAlertMsg('처리상태를 선택해주세요.')
      onErrorAlertState(true)
      return
    }
    onErrorAlertState(false)
    setDefaultPayload({
      searchCoId: user.domain,
      searchComplyWith: '',
      servicesWord: '',
      searchServIds: '',
      searchType: searchType,
      searchWord: inputValue,
      searchReqTySe: reqType,
      searchReqPathSe: '',
      searchRegTySe: '',
      searchPeriodType: periodType,
      startDate: rangeObj.start,
      endDate: rangeObj.end,
      searchTypeTime: 1,
      searchHdlStatuses: checkedList.join(),
      perPage: 1,
      page: 1,
    })
  }

  const [N, setN] = useState(10)

  const loadMore = () => {
    setN(N + 10)
  }

  const renderButton = () => {
    if (totalRequestList?.ids?.length <= N) {
      return <></>
    } else if (totalRequestList?.ids?.length > N) {
      return (
        <Button
          type='shuffle'
          variant='secondary'
          onClick={loadMore}>
          더보기
          <span className='arrow__icon expand' />
        </Button>
      )
    }
  }

  let totalRequestListCode
  if (
    isTotalRequestListLoading ||
    (isTotalRequestFetching && deptUserLists && deptUserLists.entities)
  ) {
    totalRequestListCode = <Loader className='loading' />
  } else if (isTotalRequestListSuccess && existDeptUser) {
    totalRequestListCode = (
      <CodeList
        list={Object.entries(totalRequestList.entities)
          .slice(0, N)
          .map(([ids, entities]) => {
            const linkTo = `/stats-request-totlist-detail/${entities.reqTySe}/${entities.srId}`

            return {
              code: entities.reqTySeNm,
              code_number: entities.srId,
              state: entities.hdlStsSeNm,
              content: entities.servNm,
              classification: entities.cltReqClNm,
              date: entities.reqDt,
              manager: entities.hdlNm,
              managerPosition: entities.hdlPosiNm,
              linkTo: linkTo,
              reqManager: entities.qusrNm,
            }
          })}
      />
    )
  } else if (!existDeptUser) {
    return <ErrorPageNoAuth />
  }

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div className='search__card'>
          <Dropdown
            id='select_type'
            label='요청유형'
            options={reqTypeOptions}
            onChange={e => setReqType(e.curr.value)}
          />

          <span className='text-primary fw-600'>요청일</span>
          <div className='d-flex mb-8'>
            <Radio
              label='요청일'
              name='searchPeriodType'
              value='0254'
              onClick={periodTypeChange}
              defaultChecked
            />
            <Radio
              label='완료일'
              name='searchPeriodType'
              onClick={periodTypeChange}
              value='0255'
            />
            <Radio
              label='완료합의일'
              name='searchPeriodType'
              onClick={periodTypeChange}
              value='0256'
            />
          </div>
          <OneCalendar
            type='range'
            id='range1'
            onChange={onChangeRangePicker}
          />

          {!!isExpand && (
            <>
              <span className='text-primary fw-600'>검색어</span>
              <div className='d-flex'>
                <Dropdown
                  id='select_search'
                  options={searchTypeOptions}
                  onChange={e => setSearchType(e.curr.value)}
                  width='132px'
                />
                <Input
                  className='mb-20 ml-4 mt-6'
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                />
              </div>

              <span className='text-primary fw-600'>처리상태</span>
              <div className='d-flex flex-wrap'>
                <Checkbox
                  label='요청'
                  value='0145'
                  defaultChecked
                  name='searchHdlStatuses'
                  onChange={e => {
                    checkedHandler(e.currentTarget.checked, e.target.value)
                  }}
                />
                <Checkbox
                  label='반려종료'
                  value='0146'
                  onChange={e => {
                    checkedHandler(e.currentTarget.checked, e.target.value)
                  }}
                  defaultChecked
                />
                <Checkbox
                  label='작업진행'
                  value='0147'
                  onChange={e => {
                    checkedHandler(e.currentTarget.checked, e.target.value)
                  }}
                  defaultChecked
                />
                <Checkbox
                  label='정상종료'
                  value='0150'
                  onChange={e => {
                    checkedHandler(e.currentTarget.checked, e.target.value)
                  }}
                  defaultChecked
                />
              </div>
            </>
          )}

          <Button
            type='btn'
            onClick={onClickSearchButton}>
            조회
          </Button>
          <Button
            type='shuffle'
            variant='secondary'
            onClick={onClickExpandButton}>
            {isExpand ? '닫기' : '검색조건 더보기'}
            <span className={`arrow__icon ${isExpand ? '' : 'expand'}`}> </span>
          </Button>
        </div>

        <div className='search__list'>
          <div className='search__list-title mb-12'>
            <h2>서비스 요청 목록</h2>
            <span>{totalRequestList?.ids?.length || 0} 건</span>
          </div>

          {totalRequestListCode}

          {renderButton()}
        </div>
      </div>

      <Alert
        isShow={isShowErrorAlert}
        type='error'
        content={`${alertMsg}`}
        hideClose
        onConfirm={() => onErrorAlertState(false)}
        onClose={() => onErrorAlertState(false)}
      />
    </main>
  )
}
