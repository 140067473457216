import { useCallback, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/common/TheButton'
import Alert from '../../components/common/TheAlert'
import { useReissuePassMutation } from './helpaccountSlice'
import ErrorPage from '../errorPage/ErrorPage'
import Loader from '../../components/common/TheLoader'

export default function AccountLock() {
  const location = useLocation()
  const userInfo = {
    userId: location?.state?.userId ?? 'noData',
    coId: location?.state?.coId ?? 'noData',
  }

  const [
    reissuePassword,
    {
      isLoading: isReissuePasswordLoading,
      isSuccess: isReissuePasswordSuccess,
    },
  ] = useReissuePassMutation()

  const navigate = useNavigate()

  const [isShowAlert, setIsShowAlert] = useState(false)
  const [isShowErrorAlert, setIsShowErrorAlert] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')

  const onChangeAlertState = useCallback(value => {
    setIsShowAlert(value)
  }, [])

  const onErrorAlertState = useCallback(value => {
    setIsShowErrorAlert(value)
  }, [])

  if (userInfo.userId === 'noData' || userInfo.coId === 'noData') {
    return <ErrorPage />
  }

  const handleReissuePassword = async event => {
    event.preventDefault()

    try {
      await reissuePassword(userInfo).unwrap()
      setAlertMsg('안내메일이 발송되었습니다. 로그인 페이지로 이동합니다.')
      onErrorAlertState(false)
      onChangeAlertState(true)
    } catch (err) {
      let errormsg = err.data?.message
      setAlertMsg(errormsg)
      onChangeAlertState(false)
      onErrorAlertState(true)
    }
  }

  return (
    <main className='content login'>
      <div className='loginBox lock'>
        <i className='logo'>USTRA ITSM</i>
        <h1 className='page__title'>계정 잠김</h1>

        {isReissuePasswordLoading && <Loader className='loading' />}

        <div className='mt-20'>
          계정이 잠겼습니다. 잠금을 해제하려면 아래 이메일로 패스워드 다시 받기
          버튼을 클릭하여 주세요.
        </div>

        <Button onClick={handleReissuePassword}>
          이메일로 패스워드 재발급
        </Button>

        <Link to='/login'>
          <div className='login__link'>로그인 페이지로 가기</div>
        </Link>
      </div>
      <footer className='footer'>
        <p className='footer__copyright'>
          COPYRIGHT © 2023 GS ITM. ALL RIGHTS RESERVED.
        </p>
      </footer>

      <Alert
        isShow={isShowAlert}
        type='confirm'
        content={`${alertMsg}`}
        hideClose
        onConfirm={() => navigate('/login')}
        onClose={() => navigate('/login')}
      />
      <Alert
        isShow={isShowErrorAlert}
        type='error'
        content={`${alertMsg}`}
        hideClose
        onConfirm={() => onErrorAlertState(false)}
        onClose={() => onErrorAlertState(false)}
      />
    </main>
  )
}
