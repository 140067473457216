import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Input from '../components/common/TheInput'
import Button from '../components/common/TheButton'
import Alert from '../components/common/TheAlert'

export default function Login() {
  const navigate = useNavigate()

  const [currentPwd, setCurrentPwd] = useState('')
  const [changePwd, setchangePwd] = useState('')
  const [reChangePwd, setreChangePwd] = useState('')
  const [isShowAlert, setIsShowAlert] = useState(false)

  const onChangeAlertState = useCallback(value => {
    setIsShowAlert(value)
  }, [])

  return (
    <main className='content login'>
      <div className='loginBox'>
        <i className='logo'>USTRA ITSM</i>
        <h1 className='page__title'>초기 패스워드 설정</h1>
        <Input
          type='password'
          className='mt-20 mb-10'
          value={currentPwd}
          onChange={e => setCurrentPwd(e.target.value)}
          placeholder='현재 사용 중인 비밀번호를 입력하세요.'
        />
        <Input
          type='password'
          className='mt-10 mb-10'
          value={changePwd}
          onChange={e => setchangePwd(e.target.value)}
          placeholder='변경할 비밀번호를 입력하세요.'
        />
        <Input
          type='password'
          className='mt-10 mb-10'
          value={reChangePwd}
          onChange={e => setreChangePwd(e.target.value)}
          placeholder='변경할 비밀번호를 한번 더 입력하세요.'
        />

        <div className='pwd__rule'>
          비밀번호 규칙 : 영문 대소문자/숫자/특수문자 포함 6~17자
        </div>

        <Button onClick={() => onChangeAlertState(true)}>비밀번호 변경</Button>
      </div>
      <footer className='footer'>
        <p className='footer__copyright'>
          COPYRIGHT © 2023 GS ITM. ALL RIGHTS RESERVED.
        </p>
      </footer>

      <Alert
        isShow={isShowAlert}
        type='confirm'
        content={`비밀번호가 변경되었습니다. 로그인페이지로 이동합니다.`}
        hideClose
        onConfirm={() => navigate('/ui-page/login')}
      />
    </main>
  )
}
