import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_BASE_URL === 'http://localhost:8081'
        ? `http://${window.location.hostname}:8081`
        // : process.env.REACT_APP_BASE_URL,
       : 'https://' + window.location.hostname.substring(2),
    prepareHeaders: (headers, { getState }) => {
      // const token = (getState() as RootState).auth.token
      // if (token) {
      //   headers.set('authorization', `Bearer ${token}`)
      // }
      return headers
    },
    credentials: 'include',
  }),
  tagTypes: [
    'AddResponse',
    'Agree',
    'ApprLines',
    'ClientReqType',
    'DayReqRat',
    'DocsApproved',
    'DocsForApproval',
    'DocsWritten',
    'Domain',
    'InitPassword',
    'membProcChart',
    'ProgressList',
    'ReissuePassword',
    'ResponseList',
    'Req',
    'ReqType',
    'RequestInfo',
    'Return',
    'WithDraw',
    'DeptUserList',
  ],
  endpoints: builder => ({}),
})
