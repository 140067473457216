import { useEffect } from 'react'

const ProgressApproval = ({ progressData }) => {

  useEffect(() => {
    // Scroll 맨 끝 우측으로 이동
    const scroll_el = document.querySelector('.approval__progressList')
    scroll_el.scrollLeft = scroll_el.scrollWidth
  }, [])

  return (
    <div className='approval__progress'>
      <h3>진행상태</h3>
      <ol className='approval__progressList'>
        {progressData.map((data, index) => (
          <li key={index}>
            <div className={data.progress}>
              <span className='status'>{data.status}</span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default ProgressApproval
