import { createSlice } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/services/apiSlice'
import type { RootState } from '../../app/store'

export interface LoginRequest {
  username: string
  password: string
  domain: string
}

export interface UserResponse {
  user: {
    domain: string | null
    id: string | null
    name: string | null
    session: string | null
  }
}

type AuthState = {
  user: {
    domain: string | null
    id: string | null
    name: string | null
    session: string | null
  }
}

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: credentials => ({
        url: 'login',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(Object.entries(credentials)).toString(),
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'logout',
        method: 'GET',
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
  }),
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: { domain: null, id: null, name: null, session: null },
  } as AuthState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        extendedApiSlice.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.user = {
            domain: payload.user.domain,
            id: payload.user.id,
            name: payload.user.name,
            session: payload.user.session,
          }
        }
      )
      .addMatcher(extendedApiSlice.endpoints.logout.matchFulfilled, state => {
        state.user = {
          domain: null,
          id: null,
          name: null,
          session: null,
        }
      })
  },
})

export const { useLoginMutation, useLogoutMutation, util } = extendedApiSlice

export const selectUser = (state: RootState) => state.auth.user
