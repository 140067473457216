import { useState } from 'react'
import {
  useGetClientReqTypeQuery,
  useGetReqQuery,
  useGetReqTypeQuery,
} from './requestSlice'
import Dropdown from '../../components/common/TheSelect'
import OneCalendar from '../../components/common/TheOneCalendar'
import Button from '../../components/common/TheButton'
import CodeList from '../../components/common/TheCodeListForRequest'
import Input from '../../components/common/TheInput'
import { format, sub } from 'date-fns'
import Loader from '../../components/common/TheLoader'

export default function SearchRequest() {
  const [isExpand, setIsExpand] = useState(false)

  // 요청유형
  const [reqType, setReqType] = useState('')
  let {
    data: reqTypes = {},
    isLoading: isReqTypeLoading,
    isSuccess: isReqTypeSuccess,
  } = useGetReqTypeQuery({
    cmmCodList: [
      {
        cmmCodCl: '0175',
        companyAt: false,
        useAt: 'Y',
      },
    ],
  })

  let reqTypeOptions
  if (isReqTypeLoading) {
    reqTypeOptions = [
      {
        label: '로딩중...',
        value: '',
      },
    ]
  } else if (isReqTypeSuccess) {
    reqTypeOptions = Object.entries(reqTypes.entities).map(
      ([ids, entities]) => ({
        label: entities.cmmCodNm,
        value: entities.cmmCodCl,
      })
    )
  }

  //고객요청분류 페이로드
  const [reqTySe, setReqTySe] = useState('')
  let {
    data: ClientReqTypeList = {},
    isLoading: isClientReqTypeListLoading,
    isSuccess: isClientReqTypeListSuccess,
  } = useGetClientReqTypeQuery({
    reqTySe: '',
    useAt: 'Y',
  })

  if (reqTySe === '') {
    ClientReqTypeList = {
      ids: { ...ClientReqTypeList.ids, 0: 0 },
      entities: {
        ...ClientReqTypeList.entities,
        0: { id: 0, reqCl: '', reqClNm: '전체' },
      },
    }
  }

  let reqTySeOptions
  if (isClientReqTypeListLoading) {
    reqTySeOptions = [
      {
        label: '로딩중...',
        value: '',
      },
    ]
  } else if (isClientReqTypeListSuccess) {
    reqTySeOptions = Object.entries(ClientReqTypeList.entities).map(
      ([ids, entities]) => ({
        label: entities.reqClNm,
        value: entities.reqCl,
      })
    )
  }

  //요청일
  const today = format(new Date(), 'yyyyMMdd')
  const oneMonthAgo = format(
    sub(new Date(), {
      months: 1,
    }),
    'yyyyMMdd'
  )

  const [rangeObj, setRangeObj] = useState({
    start: oneMonthAgo,
    end: today,
  })

  const onChangeRangePicker = ({ start, end }) => {
    setRangeObj({
      start: format(start, 'yyyyMMdd'),
      end: format(end, 'yyyyMMdd'),
    })
  }

  // 검색어
  const searchTypeOptions = [
    { label: '요청번호', value: '1' },
    { label: '제목', value: '4' },
    { label: '내용', value: '5' },
  ]

  const [searchType, setSearchType] = useState(searchTypeOptions[0].value)
  const [inputValue, setInputValue] = useState('')

  const onClickExpandButton = _ => {
    setIsExpand(!isExpand)
  }

  // 조회
  const [defaultPayload, setDefaultPayload] = useState({
    sReqTySe: '',
    reqCl: '',
    schCond: '1',
    searchword: '',
    chkActivity: 'req,appl',
    fromDate: rangeObj.start,
    toDate: rangeObj.end,
    perPage: 1,
    page: 1,
  })

  const {
    data: RequestType = [],
    isLoading: isRequestTypeLoading,
    isSuccess: isRequestTypeSuccess,
    isFetching: isReqeustTypeFetching,
  } = useGetReqQuery(defaultPayload)

  const onClickSearchButton = e => {
    setDefaultPayload({
      sReqTySe: reqType,
      reqCl: '',
      schCond: searchType,
      searchword: inputValue,
      chkActivity: 'req,appl',
      fromDate: rangeObj.start,
      toDate: rangeObj.end,
      perPage: 1,
      page: 1,
    })
    console.table([
      ['요청유형', reqType],
      // ['고객요청분류', reqTySe],
      ['요청일', rangeObj],
      ['검색어 유형', searchType],
      ['검색어', inputValue],
    ])
  }

  const [N, setN] = useState(10)

  const loadMore = () => {
    setN(N + 10)
  }

  const renderButton = () => {
    if (RequestType?.ids?.length <= N) {
      return <></>
    } else if (RequestType?.ids?.length > N) {
      return (
        <Button
          type='shuffle'
          variant='secondary'
          onClick={loadMore}>
          더보기
          <span className='arrow__icon expand' />
        </Button>
      )
    }
  }

  let requestTypeListCode
  if (isRequestTypeLoading || isReqeustTypeFetching) {
    requestTypeListCode = <Loader className='loading' />
  } else if (isRequestTypeSuccess) {
    requestTypeListCode = (
      <CodeList
        list={Object.entries(RequestType.entities)
          .slice(0, N)
          .map(([ids, entities]) => {
            const linkTo =
              entities.isAp === 'AP'
                ? `/search-request/${entities.createCoId}/${entities.qusrId}/${entities.realSrId}`
                : `/search-request/${entities.sReqTySe}/${entities.srId}`
            return {
              code: entities.reqTyNm,
              code_number: entities.srId,
              state: entities.stateName,
              content: entities.srTitlNm,
              classification: entities.cltReqClNm,
              date: entities.reqDt,
              reqManager: entities.qusrNm,
              manager: entities.hdlName,
              linkTo: linkTo,
            }
          })}
      />
    )
  }

  return (
    <main className='content pt-60'>
      <div className='content__blocks p-0'>
        <div className='search__card'>
          <Dropdown
            id='select_type'
            label='요청유형'
            options={reqTypeOptions}
            onChange={e => setReqType(e.curr.value)}
          />
          {/* <Dropdown
            id='select_type'
            label='고객요청분류'
            options={reqTySeOptions}
            onChange={e => setReqTySe(e.curr.value)}
          /> */}
          <OneCalendar
            type='range'
            id='range1'
            label='요청일'
            onChange={onChangeRangePicker}
          />
          {!!isExpand && (
            <>
              <span className='text-primary fw-600'>검색어</span>
              <div className='d-flex'>
                <Dropdown
                  id='select_search'
                  options={searchTypeOptions}
                  onChange={e => setSearchType(e.curr.value)}
                  width='132px'
                />
                <Input
                  className='mb-20 ml-4 mt-6'
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                />
              </div>
            </>
          )}
          <Button
            type='btn'
            onClick={onClickSearchButton}>
            조회
          </Button>
          <Button
            type='shuffle'
            variant='secondary'
            onClick={onClickExpandButton}>
            {isExpand ? '닫기' : '검색조건 더보기'}
            <span className={`arrow__icon ${isExpand ? '' : 'expand'}`}> </span>
          </Button>
        </div>

        <div className='search__list'>
          <div className='search__list-title mb-12'>
            <h2>서비스 요청 목록</h2>
            <span>{RequestType?.ids?.length || 0} 건</span>
          </div>

          {requestTypeListCode}

          {renderButton()}
        </div>
      </div>
    </main>
  )
}
