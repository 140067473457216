import { useState } from 'react'
import { Link } from 'react-router-dom'

const Tabs = ({ children, activeValue }) => {
  const [activeTab, setActiveTab] = useState(children[activeValue].props.label)
  const [elChildren, setElChildren] = useState(
    children[activeValue].props.children
  )

  const handleTabClick = (e, newActiveTab, children) => {
    e.preventDefault()
    setActiveTab(newActiveTab)
    setElChildren(children)
  }

  return (
    <div className='tabs'>
      <ul className='tab__list'>
        {children.map(child => {
          const { label, children } = child.props

          return (
            <li
              key={label}
              className='tab__item'>
              <Link
                className={`tab__link ${
                  activeTab === label ? 'is-active' : ''
                }`.trim()}
                onClick={e => handleTabClick(e, label, children)}>
                {label}
              </Link>
            </li>
          )
        })}
      </ul>
      {!!elChildren && <div className='tab-content'>{elChildren}</div>}
    </div>
  )
}

const Tab = ({ children }) => {
  return <>{children}</>
}

export { Tabs, Tab }
